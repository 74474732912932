import { createSlice } from "@reduxjs/toolkit";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import {
  doc,
  collection,
  setDoc,
  getDocs,
  getDoc,
  deleteDoc,
  updateDoc,
  addDoc,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../../services/firebase";

export enum LocalStorageKeys {
  userToken = "userToken",
}
const initialState = {
  users: [],
  events: [],
  fetchingUsers: false,
};

export const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setFetchingUsers: (state, action) => {
      state.fetchingUsers = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
  },
});
export const createUser =
  (
    firstname: string,
    lastname: string,
    email: string,
    company: string,
    profiles: string[]
  ) =>
  async (dispatch: Function) => {
    dispatch(setFetchingUsers(true));
    try {
      const auth = getAuth();
      const newAuthUser = await createUserWithEmailAndPassword(
        auth,
        email,
        "Aa123456+"
      );
      //create new document in firestore with all properties
      const userDocRef = doc(db, "users", newAuthUser.user.uid);
      const newDBUser = {
        firstname,
        lastname,
        email,
        company,
        profiles,
        firstLogin: true,
        eulaAccepted: false,
        admin: false,
        analytics: {
          logins: [],
        },
      };
      setDoc(userDocRef, newDBUser);
      await new Promise((r) => setTimeout(r, 1000));
      dispatch(setFetchingUsers(false));
    } catch (error) {
      console.error(error);
      dispatch(setUsers(false));
    }
  };

export const updateUser =
  (
    id: string,
    firstname: string,
    lastname: string,
    email: string,
    company: string,
    profiles: string[]
  ) =>
  async (dispatch: Function) => {
    dispatch(setFetchingUsers(true));
    try {
      const userDocRef = doc(db, "users", id);
      const dbUser = await getDoc(userDocRef);
      const userData = dbUser.data();
      const newDBUser = {
        ...userData,
        firstname,
        lastname,
        email,
        company,
        profiles,
      };
      setDoc(userDocRef, newDBUser);
      await new Promise((r) => setTimeout(r, 1000));
      dispatch(setFetchingUsers(false));
    } catch (error) {
      console.error(error);
      dispatch(setUsers(false));
    }
  };

export const getAllUsers = () => async (dispatch: Function) => {
  try {
    const userRef = collection(db, "users");
    const users = await getDocs(userRef);
    const formattedUsers = users.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    dispatch(setUsers(formattedUsers));
  } catch (error) {
    console.error(error);
  }
};

export const deleteUserById = (user: any) => async (dispatch: Function) => {
  try {
    const auth = getAuth();
    const userRef = doc(db, "users", user.id);
    await deleteDoc(userRef);
    // deleteUser(user.id);
    await new Promise((r) => setTimeout(r, 1000));
    dispatch(getAllUsers());
  } catch (error) {
    console.error(error);
  }
};
export const addEvent = (event: any) => async (dispatch: Function) => {
  try {
    const eventRef = collection(db, "events");
    await addDoc(eventRef, event);
    await new Promise((r) => setTimeout(r, 1000));
  } catch (error) {
    console.error(error);
  }
};

export const updateAnalyticsLogins =
  (id: string) => async (dispatch: Function) => {
    try {
      const userDocRef = doc(db, "users", id);
      const dbUser = await getDoc(userDocRef);
      const userData = dbUser.data();
      const newConn = {
        date: new Date().toISOString(),
        duration: 0,
      };
      const newLogins = [...userData.analytics.logins, newConn];
      updateDoc(userDocRef, {
        "analytics.logins": newLogins,
      });
      await new Promise((r) => setTimeout(r, 1000));
    } catch (error) {
      console.error(error);
    }
  };

export const getEventsSortedByDate = () => async (dispatch: Function) => {
  try {
    const q = query(collection(db, "events"), orderBy("date", "desc")); // "desc" for descending order, "asc" for ascending
    const querySnapshot = await getDocs(q);
    const formattedEvents = [];
    querySnapshot.forEach((doc) => {
      formattedEvents.push({ ...doc.data() });
    });
    dispatch(setEvents(formattedEvents));
  } catch (error) {
    console.error(error);
  }
};

export const migrateUsers = () => async (dispatch: Function) => {
  try {
    const userRef = collection(db, "events");
    const users = await getDocs(userRef);
    users.forEach(async (document) => {
      const user = document.data();
      const userDocRef = doc(db, "events", document.id);
      if (Object.hasOwn(user.profile, "code")) {
        updateDoc(userDocRef, {
          profile: user.profile.code,
        });
      }
    });
    await new Promise((r) => setTimeout(r, 1000));
    dispatch(getAllUsers());
  } catch (error) {
    console.error(error);
  }
};

export const updateConnectionTime =
  (id: string, connectionTime: number) => async (dispatch: Function) => {
    try {
      const userDocRef = doc(db, "users", id);
      const dbUser = await getDoc(userDocRef);
      const userData = dbUser.data();
      const lastLogin =
        userData.analytics.logins[userData.analytics.logins.length - 1];
      lastLogin.duration += connectionTime;
      updateDoc(userDocRef, {
        "analytics.logins": [...userData.analytics.logins],
      });
      await new Promise((r) => setTimeout(r, 1000));
      dispatch(getAllUsers());
    } catch (error) {
      console.error(error);
    }
  };
export const { setUsers, setEvents, setFetchingUsers } = userSlice.actions;

export default userSlice.reducer;
