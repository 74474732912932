// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.more-info-p__title {
  text-align: left;
  font-size: 1.15rem;
  margin-bottom: 1rem;
  color: white;
}
.more-info-p__text {
  color: white;
  text-wrap: wrap;
  text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/more-info-p/more-info-p.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AAAR;AAEI;EACI,YAAA;EACA,eAAA;EACA,iBAAA;AAAR","sourcesContent":[".more-info-p{\n    &__title{\n        text-align: left;\n        font-size: 1.15rem;\n        margin-bottom: 1rem;\n        color: white;\n    }\n    &__text{\n        color:white;\n        text-wrap: wrap;\n        text-align: start;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
