import { useCallback, useEffect } from "react";
import "./performance-chart-pdf.scss";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getPerformanceChartSeries } from "features/profile/profileSlices";
import { StringStore } from "StringStore";
import { ClipLoader } from "react-spinners";

const getOptions = (labels: [], max, min) => {
  const options: ApexCharts.ApexOptions = {
    markers: {
      hover: {
        size: 0,
      },
    },
    tooltip: {
      x: {
        show: false,
      },
      enabled: false,
    },
    legend: {
      offsetX: 5,
      onItemHover: {
        highlightDataSeries: false,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      position: "bottom",
      fontSize: "10px",
      labels: {
        colors: "",
      },
      markers: {
        radius: 1,
        height: 3,
        width: 26,
      },
    },
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "straight",
      width: 1,
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      tickAmount: 4,
      categories: labels,
      labels: {
        style: {
          colors: "#000000",
          fontSize: "10px",
        },
        rotate: 0,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
        color: "#000000",
        offsetY: 0.75,
      },
    },
    grid: {
      show: true,
    },
    yaxis: {
      tickAmount: max - min,
      max: max,
      min: min,
      labels: {
        style: {
          colors: "#000000",
          fontSize: "10px",
        },
        formatter: (value) => {
          return value.toFixed(0);
        },
      },
      axisBorder: {
        show: false,
        offsetX: -2.2,
        color: "#000000",
      },
    },
    colors: ["#123465", "#F5A623"],
  };
  return options;
};

const PerformanceChartPdf = () => {
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const data = useSelector((state: any) => state.profile.performanceChartData);

  useEffect(() => {
    dispatch(getPerformanceChartSeries(currentProfile.code));
  }, [currentProfile.code]);
  const getMax = useCallback(
    (series) => {
      if (series.length === 0) {
        return 0;
      }
      const max = Math.max(...series[0].data, ...series[1].data);
      return max;
    },
    [data.series]
  );

  const getMin = useCallback(
    (series) => {
      if (series.length === 0) {
        return 0;
      }
      const min = Math.min(...series[0].data, ...series[1].data);
      return min;
    },
    [data.series]
  );

  const max = getMax(data.series) + 1;
  const min = getMin(data.series);
  return (
    <div className="performance-pdf">
      <div className="performance-pdf__title">Evolution de la performance</div>
      {!!data.series.length ? (
        <ReactApexChart
          options={getOptions(data.labels, max, min)}
          series={data.series}
          type="line"
          width={"95%"}
          height={180}
        />
      ) : (
        <div className="performance-pdf__content">
          <ClipLoader color={"#EF936C"} loading={true} size={100} />
        </div>
      )}
      <div className="performance-pdf__disclaimer">
        {StringStore.profile.performanceDisclaimer}
      </div>
    </div>
  );
};

export default PerformanceChartPdf;
