import './staff-container.scss'
import GillesSvg from 'assets/profile/gilles.svg'
import IonelSvg from 'assets/profile/ionel.svg'
const StaffContainer = () => {
    const people = [
        {
            name: 'Gilles Etcheberrigaray',
            role: 'Président et Directeur des investissements',
            imageSrc: GillesSvg
         },
        {
            name: 'Ionel Sbiera, CFA',
            role: 'Directeur Général Délégué en charge de la gestion',
            imageSrc: IonelSvg
        }]
    return (
        <div className="staff-container">
            <div className="staff-container__title">
                L'équipe
            </div>
            <div className="staff-container__people">
            {
                people.map((person) => {
                    return (
                        <div key={person.imageSrc} className="staff-container__people__person">
                            <img src={person.imageSrc} alt="Logo" className="staff-container__people__person__image"/>
                            <div className="staff-container__people__person__name">{person.name}</div>
                            <div className="staff-container__people__person__role">{person.role}</div>
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
}

export default StaffContainer;