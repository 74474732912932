import { StringStore } from "StringStore";
import "./full-commentary-pdf.scss";
import { FC, useEffect } from "react";
import CloseIcon from "assets/admin/close-ic.svg";
import { useDispatch, useSelector } from "react-redux";
import { textFormatter } from "utils/fileFormatter";
import { getCommentary } from "features/profile/profileSlices";

interface FullCommentaryProps {
  isVisible: boolean;
  setVisible: Function;
}

const FullCommentaryPdf: FC<FullCommentaryProps> = ({
  isVisible,
  setVisible,
}) => {
  const commentary = useSelector((state: any) => state.profile.commentary);
  const formattedCommentary = textFormatter(commentary);
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const dispatch: Function = useDispatch();

  useEffect(() => {
    dispatch(getCommentary(currentProfile.code));
  }, [currentProfile.code]);

  return (
    <div className={`full-commentary-pdf`}>
      <div className="full-commentary-pdf__header">
        <div className="full-commentary-pdf__header__title">
          {StringStore.profile.commentaryTitle}
        </div>
      </div>
      <div
        className="full-commentary-pdf__content"
        dangerouslySetInnerHTML={{ __html: formattedCommentary }}
      ></div>
    </div>
  );
};

export default FullCommentaryPdf;
