// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.performance-pdf {
  width: 100%;
  height: 28%;
  position: relative;
  margin-top: 1cm;
}
.performance-pdf__title {
  font-size: 0.5cm;
  font-weight: 800;
  margin-bottom: 0.25cm;
  text-align: start;
  padding-left: 0.5cm;
}
.performance-pdf__disclaimer {
  text-align: start;
  font-size: 0.25cm;
  width: 90%;
  padding: 0.5cm;
}
.performance-pdf__content {
  height: 5%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5cm;
}`, "",{"version":3,"sources":["webpack://./src/features/pdf/components/performance-chart-pdf/performance-chart-pdf.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AACJ;AAAI;EACI,gBAAA;EACA,gBAAA;EACA,qBAAA;EACA,iBAAA;EACA,mBAAA;AAER;AAAI;EACI,iBAAA;EACA,iBAAA;EACA,UAAA;EACA,cAAA;AAER;AAAI;EACI,UAAA;EACA,UAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;AAER","sourcesContent":[".performance-pdf{\n    width: 100%;\n    height: 28%;\n    position: relative;\n    margin-top: 1cm;\n    &__title{\n        font-size: 0.5cm;\n        font-weight: 800;\n        margin-bottom: 0.25cm;\n        text-align: start;\n        padding-left: 0.5cm;\n    }\n    &__disclaimer{\n        text-align: start;\n        font-size: 0.25cm;\n        width: 90%;\n        padding: 0.5cm;\n    }\n    &__content{\n        height: 5%;\n        width: 90%;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        padding: 0.5cm;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
