// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.keys-container {
  width: 50%;
  height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.keys-container__title {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 2rem;
}
.keys-container__disclaimer {
  font-size: 0.75rem;
  font-weight: 400;
  text-align: left;
  padding: 0.4rem;
}
.keys-container__keys {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 0.5rem;
}
.keys-container__keys__one {
  display: flex;
  flex-direction: column;
}
.keys-container__keys__one__title {
  margin-bottom: 1rem;
  max-width: 9rem;
  height: 3rem;
  text-wrap: wrap;
  font-size: 1.125rem;
}
.keys-container__keys__one__value {
  font-size: 1.5rem;
  color: #EF936C;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/keys-container/keys-container.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,2BAAA;AACJ;AAAI;EACI,kBAAA;EACA,gBAAA;EACA,mBAAA;AAER;AAAI;EACI,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;AAER;AAAI;EACI,aAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,6BAAA;EACA,qBAAA;AAER;AADQ;EACI,aAAA;EACA,sBAAA;AAGZ;AAFY;EACI,mBAAA;EACA,eAAA;EACA,YAAA;EACA,eAAA;EACA,mBAAA;AAIhB;AAFY;EACI,iBAAA;EACA,cAAA;AAIhB","sourcesContent":[".keys-container{\n    width: 50%;\n    height: 12rem;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: flex-start;\n    &__title{\n        font-size: 1.25rem;\n        font-weight: 800;\n        margin-bottom: 2rem;\n    }\n    &__disclaimer{\n        font-size: 0.75rem;\n        font-weight: 400;\n        text-align: left;\n        padding: 0.4rem;\n    }\n    &__keys{\n        display: flex;\n        width: 100%;\n        flex-direction: row;\n        align-items: center;\n        justify-content: space-evenly;\n        margin-bottom: 0.5rem;\n        &__one{\n            display: flex;\n            flex-direction: column;\n            &__title{\n                margin-bottom: 1rem;\n                max-width: 9rem;\n                height: 3rem;\n                text-wrap: wrap;\n                font-size: 1.125rem;\n            }\n            &__value{\n                font-size: 1.5rem;\n                color:#EF936C;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
