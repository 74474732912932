// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
  display: flex;
  flex-direction: column;
}
.login-form__header {
  margin-bottom: 10%;
}
.login-form__title {
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.login-form__inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.625rem;
  margin-bottom: 15%;
}
.login-form__inputs__one {
  color: #000;
  width: 18.75rem;
  font-family: "Inter";
}
.login-form__inputs__error {
  color: #FF0000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/features/authentication/components/login-form/login-form.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AAAI;EACI,kBAAA;AAER;AAAI;EACI,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAER;AAAI;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,aAAA;EACA,kBAAA;AAER;AADQ;EACI,WAAA;EACA,eAAA;EACA,oBAAA;AAGZ;AADQ;EACI,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAGZ","sourcesContent":[".login-form{\n    display: flex;\n    flex-direction: column;\n    &__header{\n        margin-bottom: 10%;\n    }\n    &__title{\n        color: #000;\n        font-size: 2rem;\n        font-style: normal;\n        font-weight: 500;\n        line-height: normal;\n    }\n    &__inputs{\n        display: flex;\n        flex-direction: column;\n        align-items: flex-end;\n        gap: 0.625rem;\n        margin-bottom: 15%;\n        &__one{\n            color:#000;\n            width: 18.75rem;\n            font-family: 'Inter';\n        }\n        &__error{\n            color: #FF0000;\n            font-size: 0.75rem;\n            font-style: normal;\n            font-weight: 500;\n            line-height: normal;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
