import { StringStore } from "StringStore"
import './full-commentary.scss'
import { FC } from "react"
import CloseIcon from 'assets/admin/close-ic.svg'

interface FullCommentaryProps {
    fullCommentary:string,
    isVisible:boolean,
    setVisible:Function
}

const FullCommentary : FC<FullCommentaryProps>= ({fullCommentary, isVisible,setVisible}) => {

    return (
      <div className={`full-commentary ${isVisible ? 'visible' : ''}`}>
      <div className="full-commentary__header">
        <div className="full-commentary__header__title">{StringStore.profile.commentaryTitle}</div>
        <img src={CloseIcon} alt="Close" className="full-commentary__header__close" onClick={()=>setVisible(false)}/>
      </div>
      <div className="full-commentary__content" dangerouslySetInnerHTML={{__html:fullCommentary}}>
      </div>
      </div>
    )
}

export default FullCommentary