// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.staff-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.staff-container__title {
  text-align: left;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: white;
}
.staff-container__people {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}
.staff-container__people__person__image {
  margin-bottom: 1rem;
}
.staff-container__people__person__name {
  font-weight: bold;
}
.staff-container__people__person__role {
  max-width: 15rem;
}
.staff-container img {
  width: 45%;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/staff-container/staff-container.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACI;EACI,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AACR;AAEI;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AAAR;AAGgB;EACI,mBAAA;AADpB;AAGQ;EACI,iBAAA;AADZ;AAGQ;EACI,gBAAA;AADZ;AAKI;EACI,UAAA;AAHR","sourcesContent":[".staff-container{\n    display: flex;\n    flex-direction: column;\n    gap:2rem;\n\n    &__title{\n        text-align: left;\n        font-size: 1.25rem;\n        margin-bottom: 1rem;\n        color: white;\n    }\n\n    &__people{\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        gap: 3rem;\n\n            &__person{\n                &__image{\n                    margin-bottom: 1rem;\n                }\n        &__name{\n            font-weight: bold;\n        }\n        &__role{\n            max-width: 15rem;\n        }\n    }\n    }\n    img{\n        width: 45%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
