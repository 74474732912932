import { TextField } from '@mui/material';
import './first-login-form.scss'
import TinyLogo from 'assets/authentication/tinyLogo.svg'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ColorButton from '../color-button/color-button';
import { StringStore } from 'StringStore';
import { useDispatch, useSelector } from 'react-redux';
import { setMyPassword } from '../../authSlices';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../types/app-routes';

const validationSchema = Yup.object().shape({
  newPassword: Yup.string().required('Required'),
  newPasswordAgain: Yup.string().required('Required')
  });

interface FirstLoginFormProps {
  newPassword: string;
  newPasswordAgain: string;
}

const FirstLoginForm = () => {
  const dispatch:Function = useDispatch()
  const navigate = useNavigate()
  const isPasswordSent = useSelector((state: any) => state.auth.isPasswordSent)
    const formik = useFormik<FirstLoginFormProps>({
        initialValues: {
          newPassword: '',
          newPasswordAgain: ''
        },
        validateOnMount: true,
        validationSchema,
        onSubmit: ({ newPassword, newPasswordAgain }) => {
          dispatch(setMyPassword(newPassword))
        }
      });
      
      useEffect(() => {
        if(isPasswordSent){
          navigate(AppRoutes.HOME)
        }
      },[isPasswordSent])

    const isSamePasswords = formik.values.newPassword === formik.values.newPasswordAgain;
    return (
        <div className="first-login-form">
            <div className="first-login-form__header">
            <img src={TinyLogo} alt="Tiny Logo" className="first-login-form__logo"/>
            <div className="first-login-form__title">{StringStore.authentication.firstLogin.title}</div>
            <div className="first-login-form__subtitle">{StringStore.authentication.firstLogin.description}</div>
            </div>
            <div className="first-login-form__inputs">
            <TextField id="standard-basic" type={'password'} name={'newPassword'} label={StringStore.authentication.firstLogin.newPassword} variant="standard" onChange={formik.handleChange} className="first-login-form__inputs__one"/>
            <TextField id="standard-basic" type={'password'} name={'newPasswordAgain'} label={StringStore.authentication.firstLogin.confirmNewPassword} variant="standard" onChange={formik.handleChange} className="first-login-form__inputs__one"/>
            </div>
            <ColorButton variant="contained" disabled={!formik.isValid || !isSamePasswords} onClick={() => formik.handleSubmit()}>{StringStore.authentication.firstLogin.buttonLabel}</ColorButton>
        </div>
    );
}

export default FirstLoginForm;