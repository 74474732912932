// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-login-form {
  display: flex;
  flex-direction: column;
  color: #000;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-family: Inter;
}
.first-login-form__header {
  margin-bottom: 10%;
}
.first-login-form__title {
  font-size: 2rem;
}
.first-login-form__subtitle {
  font-size: 1rem;
}
.first-login-form__inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.625rem;
  margin-bottom: 15%;
}
.first-login-form__inputs__one {
  color: #000;
  width: 18.75rem;
  font-family: "Inter";
}`, "",{"version":3,"sources":["webpack://./src/features/authentication/components/first-login-form/first-login-form.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAAI;EACI,kBAAA;AAER;AAAI;EACI,eAAA;AAER;AAAI;EACI,eAAA;AAER;AAAI;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,aAAA;EACA,kBAAA;AAER;AADQ;EACI,WAAA;EACA,eAAA;EACA,oBAAA;AAGZ","sourcesContent":[".first-login-form{\n    display: flex;\n    flex-direction: column;\n    color: #000;\n    font-weight: 500;\n    font-style: normal;\n    line-height: normal;\n    font-family: Inter;\n    &__header{\n        margin-bottom: 10%;\n    }\n    &__title{\n        font-size: 2rem;\n    }\n    &__subtitle{\n        font-size: 1rem;\n    }\n    &__inputs{\n        display: flex;\n        flex-direction: column;\n        align-items: flex-end;\n        gap: 0.625rem;\n        margin-bottom: 15%;\n        &__one{\n            color:#000;\n            width: 18.75rem;\n            font-family: 'Inter';\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
