// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-side-bar {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media (max-width: 1200px) {
  .info-side-bar {
    height: 92rem;
  }
}
.info-side-bar .doc-img {
  width: 98%;
}
.info-side-bar__content {
  background-color: #4BABB9;
  width: 80%;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.info-side-bar__content__title {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 1rem;
  color: white;
  text-align: left;
}
.info-side-bar__content .download {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/info-side-bar/info-side-bar.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,aAAA;EACA,uBAAA;EACA,uBAAA;AACJ;AACI;EANJ;IAOQ,aAAA;EAEN;AACF;AADI;EACI,UAAA;AAGR;AADI;EACI,yBAAA;EACA,UAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAGR;AAFQ;EACI,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;AAIZ;AADQ;EACI,eAAA;EACA,iBAAA;EACA,0BAAA;EACA,YAAA;AAGZ","sourcesContent":[".info-side-bar{\n    width: 20%;\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n\n    @media (max-width: 1200px) {\n        height: 92rem;\n    }\n    .doc-img{\n        width:98%;\n    }\n    &__content{\n        background-color:#4BABB9;\n        width: 80%;\n        border-radius: 1rem;\n        padding: 1rem;\n        display: flex;\n        flex-direction: column;\n        gap: 4rem;\n        &__title{\n            font-size: 1.25rem;\n            font-weight: 800;\n            margin-bottom: 1rem;\n            color: white;\n            text-align: left;\n        }\n\n        .download{\n            cursor: pointer;\n            font-weight: bold;\n            text-decoration: underline;\n            color: white;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
