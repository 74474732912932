import './upload-form.scss'
import CloseIcon from 'assets/admin/close-ic.svg'
import { useDispatch } from 'react-redux';
import { FC, useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';



export interface UploadFormProps {
  title:string;
  isVisible:boolean;
  type:string;
  setVisible:Function;
  fileName:string;
}

const UploadForm : FC<UploadFormProps>= ({title, isVisible, type,setVisible,fileName}) => {
  const dispatch:Function = useDispatch()
  const fileUploadRef = useRef<FileUpload>(null);
  const [file, setFile] = useState<File>();   

  const handleAction = (action: boolean) => {
    if (action && file) {
      fileUploadRef.current?.upload();
      
    }
    setFile(undefined);
    setVisible(false);
  };



  return (
      <div className={`upload-form ${isVisible ? 'visible' : ''}`}>
            <div className="upload-form__header">
              <div className="upload-form__header__title">{title}</div>
              <img src={CloseIcon} alt="Close" className="upload-form__header__close" onClick={()=>handleAction(false)}/>
            </div>
            <div className="upload-form__content">
            <FileUpload
            accept={`.${type}`}
            className="updoad-form__content__file-upload "
            chooseOptions={{ label: 'Import', icon: 'hide none', className: 'p-button-text' }}
            mode="basic"
            name="file"

            url={`https://www.avodai.com/file/upload/${fileName}`}
            ref={fileUploadRef}
            onSelect={e => {
              setFile(e.files[0]);
            }}
          />
                <div className="upload-form__content__button" onClick={()=>handleAction(true)}>Replace/Upload</div>
          </div>
      </div>
  );
}

export default UploadForm;