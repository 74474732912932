import { StringStore } from "StringStore";
import "./commentary-container.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCommentary } from "features/profile/profileSlices";
import React from "react";
import FullCommentary from "../full-commentary/full-commentary";
import { textFormatter } from "utils/fileFormatter";

const CommentaryContainer = () => {
  const dispatch: Function = useDispatch();
  const [isFullCommentaryVisible, setIsFullCommentaryVisible] = useState(false);
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const commentary = useSelector((state: any) => state.profile.commentary);
  const formattedCommentary = textFormatter(commentary);
  const previewCommentary = formattedCommentary
    ?.split(" ")
    .slice(0, 47)
    .join(" ")
    .concat("...");

  useEffect(() => {
    dispatch(getCommentary(currentProfile.code));
  }, [currentProfile.code]);

  return (
    <div className="commentary">
      <div className="commentary__title">
        {StringStore.profile.commentaryTitle}
      </div>
      <p
        className="commentary__text"
        dangerouslySetInnerHTML={{ __html: previewCommentary }}
      ></p>
      <div
        className="commentary__button"
        onClick={() => setIsFullCommentaryVisible(true)}
      >
        {StringStore.profile.commentaryButton}
      </div>
      <FullCommentary
        fullCommentary={formattedCommentary}
        isVisible={isFullCommentaryVisible}
        setVisible={setIsFullCommentaryVisible}
      />
    </div>
  );
};

export default CommentaryContainer;
