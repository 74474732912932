import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore';
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyDEdEuwRSrQAW-vcPB9Qk60Ty0j61n7y1o",
    authDomain: "elkano-20305.firebaseapp.com",
    projectId: "elkano-20305",
    storageBucket: "elkano-20305.appspot.com",
    messagingSenderId: "413396793343",
    appId: "1:413396793343:web:653f8f62b0a1685e8559ca",
    measurementId: "G-X0NEB8XKW2"
  };
const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)