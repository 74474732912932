// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-pdf {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5cm;
}
.header-pdf__title {
  width: 66%;
  margin-top: 0.5cm;
  font-size: 0.5cm;
  font-weight: 800;
  display: flex;
  justify-content: flex-end;
}
.header-pdf__last-update-box {
  width: 34%;
  display: flex;
  justify-content: center;
}
.header-pdf__last-update-box__one {
  margin-top: 0.5cm;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  font-size: 0.35cm;
  width: 4.5cm;
  height: 0.7cm;
  border-radius: 0.5cm;
}`, "",{"version":3,"sources":["webpack://./src/features/pdf/components/header-pdf/header-pdf.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,oBAAA;AACJ;AAAI;EACI,UAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,yBAAA;AAER;AAAI;EAEI,UAAA;EACA,aAAA;EACA,uBAAA;AACR;AAAQ;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;AAER","sourcesContent":[".header-pdf{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 100%;\n    margin-bottom: 0.5cm;\n    &__title{\n        width: 66%;\n        margin-top: 0.5cm;\n        font-size: 0.5cm;\n        font-weight: 800;\n        display: flex;\n        justify-content: flex-end;\n    }\n    &__last-update-box{\n        \n        width: 34%;\n        display: flex;\n        justify-content: center;\n        &__one{\n        margin-top: 0.5cm;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        background-color: black;\n        color: white;\n        font-size: 0.35cm;\n        width: 4.5cm;\n        height: 0.7cm;\n        border-radius: 0.5cm;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
