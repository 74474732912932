// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-commentary {
  visibility: hidden;
  position: fixed;
  display: flex;
  width: 60rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  background-color: white;
  z-index: 1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
}
.full-commentary__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3rem;
  border-bottom: 0.0625rem solid #ddd;
}
.full-commentary__header__title {
  margin-left: 1rem;
  font-size: 1.5rem;
  font-weight: 800;
  color: #000;
}
.full-commentary__header__close {
  scale: 0.7;
  margin-right: 1rem;
  cursor: pointer;
}
.full-commentary__content {
  padding: 1rem;
  line-height: 1.4rem;
  text-align: start;
}

.visible {
  visibility: visible !important;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/full-commentary/full-commentary.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;EACA,aAAA;EACA,YAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,sBAAA;EACA,uBAAA;EACA,UAAA;EACA,wCAAA;EACA,mBAAA;AACJ;AAAI;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,mCAAA;AAER;AADQ;EACI,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;AAGZ;AADQ;EACI,UAAA;EACA,kBAAA;EACA,eAAA;AAGZ;AAAI;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;AAER;;AACA;EACI,8BAAA;EACA,UAAA;EACA,8CAAA;AAEJ","sourcesContent":[".full-commentary{\n    visibility: hidden;\n    position: fixed;\n    display: flex;\n    width: 60rem;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    flex-direction: column;\n    background-color: white;\n    z-index: 1;\n    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);\n    border-radius: 1rem;\n    &__header{\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        width: 100%;\n        height: 3rem;\n        border-bottom: 0.0625rem solid #ddd;\n        &__title{\n            margin-left: 1rem;\n            font-size: 1.5rem;\n            font-weight: 800;\n            color: #000;\n        }\n        &__close{\n            scale: 0.7;\n            margin-right: 1rem;\n            cursor: pointer;\n        }\n    }\n    &__content{\n        padding: 1rem;\n        line-height: 1.4rem;\n        text-align: start;\n    }\n}\n.visible{\n    visibility: visible !important;\n    opacity: 1;\n    transition: visibility 0s, opacity 0.5s linear;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
