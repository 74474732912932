import { useSelector } from "react-redux";
import "./signature.scss";

const Signature = () => {
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const isDynamique = currentProfile.type === "Dynamique";
  const profile = isDynamique ? "Profil dynamique" : "Profil équilibre";
  return (
    <div className="signature">
      <div className="signature__date">
        <div className="signature__date__title">Date:</div>
        <div className="signature__date__value">{`..... / ..... / .....`}</div>
      </div>
      <div className="signature__sides">
        <div className="signature__sides__name-field">
          <div className="signature__sides__name-field__title">
            Nom du client:
          </div>
          <div className="signature__sides__name-field__value">{`.......................................................`}</div>
          <div className="signature__sides__name-field__signature">
            Signature:
          </div>
          <div className="signature__sides__name-field__value">{`.......................................................`}</div>
        </div>

        <div className="signature__sides__name-field">
          <div className="signature__sides__name-field__title">
            Nom du conseiller:
          </div>
          <div className="signature__sides__name-field__value">{`.......................................................`}</div>
          <div className="signature__sides__name-field__signature">
            Signature:
          </div>
          <div className="signature__sides__name-field__value">{`.......................................................`}</div>
        </div>
      </div>
    </div>
  );
};

export default Signature;
