// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.distribution-pdf {
  width: 50%;
  height: 20%;
  padding: 0.5cm 0.5cm 0 0.5cm;
}
.distribution-pdf__title {
  font-size: 0.5cm;
  font-weight: 800;
  text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/features/pdf/components/distribution-chart-pdf/distribution-chart-pdf.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,WAAA;EACA,4BAAA;AACJ;AACI;EACI,gBAAA;EACA,gBAAA;EACA,iBAAA;AACR","sourcesContent":[".distribution-pdf{\n    width: 50%;\n    height: 20%;\n    padding: 0.5cm 0.5cm 0 0.5cm;\n\n    &__title{\n        font-size: 0.5cm;\n        font-weight: 800;\n        text-align: start;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
