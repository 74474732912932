import { StringStore } from "StringStore";
import "./keys-container.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getProfileKeys } from "features/profile/profileSlices";

const KeysContainer = () => {
  const NO_CONTENT = "NA";
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const keysData = useSelector((state: any) => state.profile.keys);
  const risk = keysData.risk == NO_CONTENT ? NO_CONTENT : Number(keysData.risk).toFixed(1);
  const keys = [
    {
      title: `${StringStore.profile.keys.perf} ${keysData.since}`,
      value: keysData.perf == NO_CONTENT ? NO_CONTENT :`${(keysData.perf * 100).toFixed(1)}%`,
    },
    {
      title: StringStore.profile.keys.risk,
      value: `${risk} sur 7`,
    },
    {
      title: `${StringStore.profile.keys.volatility} ${keysData.since}`,
      value: keysData.volatility == NO_CONTENT ? NO_CONTENT :`${(keysData.volatility * 100).toFixed(1)}%`,
    },
    {
      title: StringStore.profile.keys.maxLoss,
      value: keysData.maxLoss == NO_CONTENT ? NO_CONTENT : `${(keysData.maxLoss * 100).toFixed(1)}%`,
    },
  ];

  useEffect(() => {
    dispatch(getProfileKeys(currentProfile.code));
  }, [currentProfile.code]);

  return (
    <div className="keys-container">
      <div className="keys-container__title">
        {StringStore.profile.keys.title}
      </div>
      <div className="keys-container__keys">
        {keys.map((key, index) => {
          return (
            <div
              key={`perf-indicator-${index}`}
              className="keys-container__keys__one__key"
            >
              <div className="keys-container__keys__one__title">
                {key.title}
              </div>
              <div className="keys-container__keys__one__value">
                {key.value}
              </div>
            </div>
          );
        })}
      </div>
      <div className="keys-container__disclaimer">
        {StringStore.profile.keys.disclaimer}
      </div>
    </div>
  );
};
export default KeysContainer;
