import { useEffect, useMemo, useState, FC } from "react";
import "./top-bar.scss";
import Logo from "assets/authentication/topBarLogo.svg";
import BlackLogo from "assets/authentication/topBarLogoBlack.svg";
import { getProfiles, setCurrentProfile } from "features/profile/profileSlices";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "features/authentication/authSlices";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "features/authentication/types/app-routes";
import { useElkanoAnalytics } from "../../hooks/useElkanoAnalytics";

interface TopBarProps {
  adminAccess: boolean;
  adminView: boolean;
}

const TopBar: FC<TopBarProps> = ({ adminAccess, adminView }) => {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();
  const { addProfileViewEvent } = useElkanoAnalytics();
  const user = useSelector((state: any) => state.auth.user);
  const profileToCode = useSelector((state: any) => state.profile.profiles);
  const basedProfile = user?.profiles?.[0] || "";
  const [selectedProfile, setSelectedProfile] = useState(basedProfile);

  useEffect(() => {
    dispatch(getProfiles());
  }, []);
  const handleSelectedProfile = (profileType: string) => {
    dispatch(setCurrentProfile(profileToCode[profileType]));
    addProfileViewEvent(profileType);
    setSelectedProfile(profileType);
  };

  const handleLogout = () => {
    dispatch(logoutUser(navigate));
  };

  const handleAdminPage = () => {
    navigate(AppRoutes.ADMIN);
  };

  const handleProfilePage = () => {
    navigate(AppRoutes.HOME);
  };
  const orderProfilesByAlphabetic = (a: any, b: any) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const profileButtons = useMemo(() => {
    if (adminAccess && adminView)
      return (
        <div
          className={`top-bar__profile-container__admin-button selected`}
          onClick={() => handleProfilePage()}
        >
          {"Retour aux profiles"}
        </div>
      );
    const userProfiles =
      user?.profiles.length > 0 && Object.keys(profileToCode).length > 0
        ? [...user.profiles]
            .map((profile) => {
              return profileToCode[profile];
            })
            .sort(orderProfilesByAlphabetic)
        : [];

    if (userProfiles.length <= 4) {
      return userProfiles.map((profileType) => {
        const isSelected = profileType === selectedProfile;
        return (
          <div
            key={profileType.code}
            className={`top-bar__profile-container__button ${
              isSelected ? "selected" : ""
            }`}
            onClick={() => handleSelectedProfile(profileType.code)}
          >
            {profileType.name}
          </div>
        );
      });
    } else {
      return (
        <div className="top-bar__profile-container__button-container">
          <select
            className="top-bar__profile-container__button-container__select"
            value={selectedProfile}
            onChange={(e) => handleSelectedProfile(e.target.value)}
          >
            {userProfiles.map((profileType) => {
              return (
                <option
                  className="top-bar__profile-container__button-container__select__option"
                  key={profileType.code}
                  value={profileType.code}
                >
                  {profileType.name}
                </option>
              );
            })}
          </select>
        </div>
      );
    }
  }, [selectedProfile, user?.profiles, profileToCode]);

  useEffect(() => {
    setSelectedProfile(basedProfile);
  }, [user?.profiles]);

  return (
    <div className="top-bar">
      <div className="top-bar__logo-container">
        <img src={adminView ? BlackLogo : Logo} alt="Logo" />
        <div
          className="top-bar__logo-container__button"
          onClick={() => handleLogout()}
        >
          Log out
        </div>
      </div>
      <div className="top-bar__profile-container">
        {adminAccess && !adminView && (
          <div
            className={`top-bar__profile-container__admin-button`}
            onClick={() => handleAdminPage()}
          >
            {"Admin"}
          </div>
        )}
        {profileButtons}
      </div>
    </div>
  );
};

export default TopBar;
