import { useDispatch, useSelector } from "react-redux";
import "./arbitration-table.scss";
import { useEffect, useState } from "react";
import { getArbitraryData } from "features/profile/profileSlices";
import ArbitrationHistory from "../arbitration-history/arbitration-history";

const ArbitrationTable = () => {
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const data = useSelector((state: any) => state.profile.arbitraryData);
  const [isHistoryTableVisible, setIsHistoryTableVisible] = useState(false);

  useEffect(() => {
    dispatch(getArbitraryData(currentProfile.code));
  }, [currentProfile.code]);

  return (
    <div className="arbitration-table">
      <div className="arbitration-table__header">
        <div className="arbitration-table__header__title">Arbitrage</div>
        <div
          className="arbitration-table__header__button"
          onClick={() => setIsHistoryTableVisible(true)}
        >
          Historique des arbitrages
        </div>
      </div>
      <div className="arbitration-table__content">
        <table>
          <thead>
            <tr>
              <th>Opération</th>
              <th>Catégorie</th>
              <th>Nom du fonds</th>
              <th>Code ISIN</th>

              <th>Variation</th>
              <th>Date d’arbitrage conseillée</th>
              <th>Pourquoi ?</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => {
              const variation = row.pretrade_allocation_weight * 100;
              const formattedVariation =
                row.Action_name === "ACHAT"
                  ? `+${variation.toFixed(1)}%`
                  : `-${variation.toFixed(1)}%`;
              const formattedDate = new Date(
                row.pre_trade_date
              ).toLocaleDateString("fr-FR");
              return (
                <tr key={index}>
                  <td className="bold">{row.Action_name}</td>
                  <td>{row.asset_class}</td>
                  <td className="bold">
                    <a
                      href={row.security_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {row.security_name}
                    </a>
                  </td>
                  <td>{row.id_security_ticker}</td>
                  <td>{formattedVariation}</td>
                  <td>{formattedDate}</td>
                  <td>{row.pre_trade_comment}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ArbitrationHistory
        isVisible={isHistoryTableVisible}
        setVisible={setIsHistoryTableVisible}
      />
    </div>
  );
};

export default ArbitrationTable;
