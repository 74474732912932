// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.distribution__title {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 1rem;
  text-align: start;
}
.distribution__content {
  height: 21.875rem;
  width: 37.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/distribution-chart/distribution-chart.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AAAR;AAEI;EACI,iBAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAR","sourcesContent":[".distribution{\n    &__title{\n        font-size: 1.25rem;\n        font-weight: 800;\n        margin-bottom: 1rem;\n        text-align: start;\n    }\n    &__content{\n        height: 21.875rem;\n        width: 37.5rem;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
