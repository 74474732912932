// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: #F4F7F9;
  font-family: "Inter";
}
.login__company-container {
  width: 56%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5%;
}
.login__form-container {
  width: 44%;
  background-color: #FFFFFF;
  border-radius: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/features/authentication/components/login/login.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,oBAAA;AACJ;AACI;EACI,UAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,OAAA;AACR;AAEI;EACI,UAAA;EACA,yBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAR","sourcesContent":[".login{\n    min-height: 100vh;\n    display: flex;\n    flex-direction: row;\n    background-color: #F4F7F9;\n    font-family: 'Inter';\n\n    &__company-container{\n        width: 56%;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        gap:5%\n    }\n\n    &__form-container{\n        width: 44%;\n        background-color: #FFFFFF;\n        border-radius: 5%;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
