import React from "react";
import "./more-info-p.scss";

interface MoreInfoPProps {
  title: string;
  content: React.ReactNode;
  extraClassName?: string;
}

const MoreInfoP: React.FC<MoreInfoPProps> = ({
  title,
  content,
  extraClassName,
}) => {
  return (
    <div className={`more-info-p ${extraClassName ?? ""}`}>
      <div className="more-info-p__title">{title}</div>
      <div className="more-info-p__text">{content}</div>
    </div>
  );
};

export default MoreInfoP;
