import { combineReducers } from '@reduxjs/toolkit'

import authReducer from '../features/authentication/authSlices'
import userReducer from '../features/authentication/userSlices'
import profileReducer from '../features/profile/profileSlices'

const store = combineReducers({
  auth: authReducer,
  user: userReducer,
  profile: profileReducer
})

export default store