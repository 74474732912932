import { Link, TextField } from '@mui/material';
import './login-form.scss'
import TinyLogo from 'assets/authentication/tinyLogo.svg'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormState } from '../login/login';
import ColorButton from '../color-button/color-button';
import { StringStore } from 'StringStore';
import { useDispatch, useSelector } from 'react-redux';
import { authenticate } from '../../authSlices';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { AppRoutes } from '../../types/app-routes';
const validationSchema = Yup.object().shape({
    email: Yup.string().required('Required'),
    password: Yup.string().required('Required')
  });

export interface LoginFormProps {
    email: string;
    password: string;
}

const LoginForm = ({setFormState}) => {
    const dispatch:Function = useDispatch()
    const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated)
    const isFirstLogin = useSelector((state: any) => state.auth.user?.firstLogin)
    const loginError = useSelector((state: any) => state.auth.error)
    const navigate = useNavigate()
    const formik = useFormik<LoginFormProps>({
        initialValues: {
          email: '',
          password: ''
        },
        validateOnMount: true,
        validationSchema,
        onSubmit: ({ email, password }) => {
            dispatch(authenticate(email, password));
        }
      });
      
    
      useEffect(() => {
        if (isAuthenticated && isFirstLogin){
          setFormState(FormState.FirstLogin)
          
        }
        else if(isAuthenticated){
          navigate(AppRoutes.HOME)
        }
        
      }, [isAuthenticated, navigate, isFirstLogin])
      
    return (
        <div className="login-form">
            <div className="login-form__header">
            <img src={TinyLogo} alt="Tiny Logo" className="login-form__logo"/>
            <div className="login-form__title">{StringStore.authentication.login.title}</div>
            </div>
            <div className="login-form__inputs">
            <TextField id="standard-basic"  name={'email'} label={StringStore.authentication.login.email} variant="standard" onChange={formik.handleChange} className="login-form__inputs__one"/>
            <TextField id="standard-basic" type={'password'} name={'password'} label={StringStore.authentication.login.password} variant="standard" onChange={formik.handleChange} className="login-form__inputs__one"/>
            <Link component={"button"} onClick={()=>setFormState(FormState.ForgotPassword)} color={"#D0D0D0"} underline="none">{StringStore.authentication.login.navigationLabel}</Link>
            {loginError && <div className="login-form__inputs__error">{StringStore.authentication.login.error}</div>}
            </div>
            <ColorButton variant="contained" disabled={!formik.isValid} onClick={() => formik.handleSubmit()}>{StringStore.authentication.login.buttonLabel}</ColorButton>
        </div>
    );
}

export default LoginForm;