// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.evolution {
  /* Adjust height for different screen sizes */
  margin-right: 5rem;
}
.evolution .react-apex-chart {
  width: 100%;
  max-width: 100%;
}
@media (max-width: 1440px) {
  .evolution .react-apex-chart {
    height: 800px; /* Set desired height for larger screens */
  }
}
.evolution__title {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 4rem;
  text-align: start;
}
.evolution__content {
  height: 21.875rem;
  width: 37.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/evolution-chart/evolution-chart.scss"],"names":[],"mappings":"AAAA;EAMM,6CAAA;EAMF,kBAAA;AATJ;AAFI;EACI,WAAA;EACA,eAAA;AAIR;AAAM;EACE;IACE,aAAA,EAAA,0CAAA;EAER;AACF;AACI;EACI,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AACR;AACI;EACI,iBAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACR","sourcesContent":[".evolution{\n    .react-apex-chart {\n        width: 100%;\n        max-width: 100%;\n      }\n      \n      /* Adjust height for different screen sizes */\n      @media (max-width: 1440px) {\n        .react-apex-chart {\n          height: 800px; /* Set desired height for larger screens */\n        }\n      }\n    margin-right: 5rem;\n    &__title{\n        font-size: 1.25rem;\n        font-weight: 800;\n        margin-bottom: 4rem;\n        text-align: start;\n    }\n    &__content{\n        height: 21.875rem;\n        width: 37.5rem;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
