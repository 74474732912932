
interface Environment {
  currentMarketDocUrl: string;
  financialMarketDocUrl: string;
  macroPointVideoUrl: string;
  monthlyChronicleUrl?: string;
}


export const environment: Environment = {
    currentMarketDocUrl: process.env.REACT_APP_MARCHES_ACTUELS_DOC_URL,
    financialMarketDocUrl: process.env.REACT_APP_MARCHES_FINANCIERS_DOC_URL,
    macroPointVideoUrl: process.env.REACT_APP_POINT_MACRO_VIDEO_URL,
    monthlyChronicleUrl: process.env.REACT_APP_MONTHLY_CHRONICLE_URL
};