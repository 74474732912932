// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload-section {
  width: 70%;
  height: 5rem;
  margin-top: 2rem;
}
.file-upload-section__title {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 2rem;
  text-align: start;
  text-decoration: underline;
}
.file-upload-section__button-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.file-upload-section__button-container__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4BABB9;
  color: black;
  width: 11rem;
  height: 2rem;
  border-radius: 0.625rem;
  align-self: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/features/admin/components/file-upload-section/file-upload-section.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,YAAA;EACA,gBAAA;AACJ;AAAI;EACI,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,0BAAA;AAER;AAAI;EACI,aAAA;EACA,6BAAA;EACA,mBAAA;AAER;AAAI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;AAER","sourcesContent":[".file-upload-section{\n    width: 70%;\n    height: 5rem;\n    margin-top: 2rem;\n    &__title{\n        font-size: 1.5rem;\n        font-weight: 400;\n        margin-bottom: 2rem;\n        text-align: start;\n        text-decoration: underline;\n    }\n    &__button-container{\n        display: flex;\n        justify-content: space-evenly;\n        align-items: center;\n    \n    &__button{\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        background-color: #4BABB9;\n        color: black;\n        width: 11rem;\n        height: 2rem;\n        border-radius: 0.625rem;\n        align-self: center;\n        cursor: pointer;\n    }\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
