import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Chip } from "@mui/material";
import useUsersCsv from "features/authentication/hooks/useUsersCsv";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserById,
  getAllUsers,
} from "features/authentication/userSlices";
import { SortOptions } from "../admin/admin";
import FileUploadSection from "../file-upload-section/file-upload-section";
import ClientForm from "../client-form/client-form";
import Pen from "assets/admin/pen.svg";
import Clone from "assets/admin/close.svg";
import "./client-db-page.scss";
import LightDownArrow from "assets/admin/light-arrow-down.svg";
import LightUpArrow from "assets/admin/light-arrow-up.svg";
import { getProfiles } from "features/profile/profileSlices";

export const getIconBySortOption = (sortOption, key = "", propToSort = "") => {
  if (key !== propToSort) return "";
  if (sortOption === SortOptions.ASC) return LightDownArrow;
  if (sortOption === SortOptions.DESC) return LightUpArrow;
  return "";
};
const ClientDBPage = ({ users }) => {
  const dispatch: Function = useDispatch();
  const profilesCode = useSelector((state: any) => state.profile.profiles);
  const { exportUsersTableToCsv, importUsersTableFromCsv } = useUsersCsv();
  const fileInputRef = useRef(null);
  const [sorted, setSorted] = useState(SortOptions.DESC);
  const [client, setClient] = useState(null);
  const [clientPopupOpen, setClientPopupOpen] = useState(false);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [propToSort, setPropToSort] = useState("lastname");
  const [tableData, setTableData] = useState([]);

    useEffect(() => {
      let sortedUsers = users;
      if (propToSort === null || sorted === null) {
        setTableData(sortedUsers);
        return;
      }
      if (sorted === SortOptions.ASC) {
        sortedUsers = [...users].sort((a, b) => {
          if (a[propToSort] < b[propToSort]) {
            return -1;
          }
          if (a[propToSort] > b[propToSort]) {
            return 1;
          }
          return 0;
        });
      }
      if (sorted === SortOptions.DESC) {
        sortedUsers = [...users].sort((a, b) => {
          if (a[propToSort] > b[propToSort]) {
            return -1;
          }
          if (a[propToSort] < b[propToSort]) {
            return 1;
          }
          return 0;
        });
      }
      setTableData(sortedUsers);
    }, [users, sorted, propToSort]);

  const handleEditClient = (user) => {
    setClient(user);
    setClientPopupOpen(true);
    dispatch(getAllUsers());
  };

  const handleDeleteClient = (user) => {
    dispatch(deleteUserById(user));
    setDeleteConfirmVisible(null);
  };

  const handleAddClient = () => {
    setClient(null);
    setClientPopupOpen(true);
    dispatch(getAllUsers());
  };

  useEffect(() => {
    dispatch(getProfiles());
  }, []);
  const handleUploadClick = useCallback(() => {
    fileInputRef.current.click();
  }, [fileInputRef]);

  const handleFileChange = (event) => {
    importUsersTableFromCsv(event.target.files[0]);
    event.target.value = null;
  };

  const sortUsersBySortOption = useMemo(() => {
    if (sorted === SortOptions.ASC) {
      const sortedUsers = [...users].sort((a, b) => {
        if (a.lastname < b.lastname) {
          return -1;
        }
        if (a.lastname > b.lastname) {
          return 1;
        }
        return 0;
      });
      console.log(sortedUsers);
      return sortedUsers;
    }
    if (sorted === SortOptions.DESC) {
      const sortedUsers = [...users].sort((a, b) => {
        if (a.lastname > b.lastname) {
          return -1;
        }
        if (a.lastname < b.lastname) {
          return 1;
        }
        return 0;
      });
      return sortedUsers;
    }

    return users;
  }, [users, sorted]);

  const handleSort = (prop) => {
    setSorted((prev) => {
      if (prev === SortOptions.ASC) return SortOptions.DESC;
      if (prev === SortOptions.DESC) return null;
      return SortOptions.ASC;
    });
    setPropToSort(prop);
  };
  const headerMap = {
    firstname: "Prénom",
    lastname: "Nom",
    email: "Email",
    company: "Société",
    profiles: "Profiles",
  };
  return (
    <div className="client-db-page">
      <FileUploadSection />
      <div className="client-db-page__button-container">
        <div
          className="client-db-page__button-container__button"
          onClick={() => handleAddClient()}
        >
          Ajouter un nouveau client
        </div>
        <div
          className="client-db-page__button-container__button"
          onClick={() => {
            const formatedUsers = users.map((user) => ({
              ...user,
              profiles: user.profiles?.map((pr) => profilesCode[pr].name),
            }));
            exportUsersTableToCsv(formatedUsers, headerMap);
          }}
        >
          Export CSV
        </div>
        <div
          className="client-db-page__button-container__button"
          onClick={handleUploadClick}
        >
          <input
            type="file"
            multiple
            // accept={'text/csv, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          Import CSV
        </div>
      </div>
      <table>
        <thead>
          <tr>
          {Object.keys(headerMap).map((key, index) => {
              return (
                <th key={index} onClick={() => handleSort(key)}>
                  <span>{headerMap[key]}</span>{" "}
                  <img src={getIconBySortOption(sorted, key, propToSort)} />
                </th>
              );
            })}
            <th>Modifier</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((user, index) => {
            return (
              <tr key={index}>
                <td>{user.firstname}</td>
                <td>{user.lastname}</td>
                <td>{user.email}</td>
                <td>{user.company}</td>
                <td style={{ maxWidth: "20rem", height: "2rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                      width: "100%",
                    }}
                  >
                    {user.profiles &&
                      user.profiles.map((value) => (
                        <Chip
                          key={value}
                          label={profilesCode[value]?.name}
                          className="chip"
                        />
                      ))}
                  </Box>
                </td>
                <td className="modify">
                  <div className="pen" onClick={() => handleEditClient(user)}>
                    <img src={Pen} alt="Modify" />
                  </div>
                  <div
                    className="delete"
                    onClick={() => setDeleteConfirmVisible(user)}
                  >
                    <img src={Clone} alt="Close" />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ClientForm
        isNew={client == null}
        client={client}
        isVisible={clientPopupOpen}
        setVisible={setClientPopupOpen}
      />
      {deleteConfirmVisible && (
        <div className="confirmation-popup">
          <h2>Êtes-vous sûr de vouloir supprimer ce client ?</h2>
          <div className="confirmation-popup__button-container">
            <div
              className="confirmation-popup__button-container__button"
              onClick={() => handleDeleteClient(deleteConfirmVisible)}
            >
              Confirm
            </div>
            <div
              className="confirmation-popup__button-container__button"
              onClick={() => setDeleteConfirmVisible(null)}
            >
              Cancel
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientDBPage;
