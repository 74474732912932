import { StringStore } from "StringStore";
import "./arbitration-history.scss";
import { FC, useEffect } from "react";
import CloseIcon from "assets/admin/close-ic.svg";
import { useDispatch, useSelector } from "react-redux";
import { getArbitraryHistoryData } from "features/profile/profileSlices";

interface FullCommentaryProps {
  isVisible: boolean;
  setVisible: Function;
}

const ArbitrationHistory: FC<FullCommentaryProps> = ({
  isVisible,
  setVisible,
}) => {
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const data = useSelector((state: any) => state.profile.arbitratyHistoryData);

  useEffect(() => {
    dispatch(getArbitraryHistoryData(currentProfile.code));
  }, [currentProfile.code]);

  return (
    <div className={`arbitration-history ${isVisible ? "visible" : ""}`}>
      <div className="arbitration-history__header">
        <div className="arbitration-history__header__title">
          Historique des arbitrages
        </div>
        <img
          src={CloseIcon}
          alt="Close"
          className="arbitration-history__header__close"
          onClick={() => setVisible(false)}
        />
      </div>
      <div className="arbitration-history__content">
        <table>
          <thead>
            <tr>
              <th>Opération</th>
              <th>Catégorie</th>
              <th>Nom du fonds</th>
              <th>Code ISIN</th>
              <th>Variation</th>
              <th>Date d’arbitrage conseillée</th>
              <th>Pourquoi ?</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => {
              const variation = row.pretrade_allocation_weight * 100;
              const formattedVariation =
                row.Action_name === "ACHAT"
                  ? `+${variation.toFixed(1)}%`
                  : `-${variation.toFixed(1)}%`;
              const formattedDate = new Date(
                row.pre_trade_date
              ).toLocaleDateString("fr-FR");
              return (
                <tr key={index}>
                  <td className="bold">{row.Action_name}</td>
                  <td>{row.asset_class}</td>
                  <td className="bold">{row.security_name}</td>
                  <td>{row.id_security_ticker}</td>
                  <td>{formattedVariation}</td>
                  <td>{formattedDate}</td>
                  <td>{row.pre_trade_comment}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ArbitrationHistory;
