import { useDispatch, useSelector } from "react-redux";
import "./wallet-table.scss";
import { useEffect } from "react";
import { getWalletData } from "features/profile/profileSlices";
import { StringStore } from "StringStore";

const WalletTable = () => {
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const data = useSelector((state: any) => state.profile.walletData);

  useEffect(() => {
    dispatch(getWalletData(currentProfile.code));
  }, [currentProfile.code]);

  const assetClassToColor = (assetClass: string) => {
    switch (assetClass) {
      case "Actions":
        return "action";
      case "Obligations":
        return "obligations";
      case "Monétaire":
        return "monetaire";
      case "Diversifié":
        return "diversifie";
      default:
        return "";
    }
  };
  return (
    <div className="wallet-table">
      <div className="wallet-table__title">Portefeuille</div>
      <div className="wallet-table__content">
        <table>
          <thead>
            <tr>
              <th>Classe d'actifs</th>
              <th>Catégorie</th>
              <th>Nom du fonds</th>
              <th>Société de gestion</th>
              <th>Code ISIN</th>
              <th>Frais de gestion</th>
              <th>SRI</th>
              <th>SFDR</th>
              <th>Poids</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => {
              const weight = row.fund_holding_security_weight_in_ptf * 100;
              const formattedWeight = `${weight.toFixed(1)}%`;
              const formattedSRI = row.risk_class_name.split(" ")[1];
              const formattedSFDR = row.sfdr_category.split(" ")[1];
              return (
                <tr key={index}>
                  <td className={`${assetClassToColor(row.asset_class)} asset`}>
                    {row.asset_class}
                  </td>
                  <td>{row.category_name}</td>
                  <td className="bold">
                    <a
                      href={row.security_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {row.security_name}
                    </a>
                  </td>
                  <td>{row.issuer_company_name}</td>
                  <td>{row.id_security_ticker}</td>
                  <td>{`${(row.security_man_fee * 100).toFixed(1)}%`}</td>
                  <td>{formattedSRI}</td>
                  <td>{formattedSFDR}</td>
                  <td>{formattedWeight}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="wallet-table__disclaimer">
        {StringStore.profile.walletDisclaimer}
      </div>
    </div>
  );
};

export default WalletTable;
