import ReactPlayer from "react-player";
import MoreInfoP from "../more-info-p/more-info-p";
import StaffContainer from "../staff-container/staff-container";
import "./info-side-bar.scss";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useEffect, useMemo } from "react";
import { saveAs } from "file-saver";
import { environment } from "environment";
import { useDispatch, useSelector } from "react-redux";
import { getMonthlyChronicleUrl } from "features/profile/profileSlices";
import chroniqueMensuelle from "assets/profile/chronicle.png";
import whatToThink from "assets/profile/whatToThink.jpg";
import whyInvest from "assets/profile/whyInvest.jpg";
const handleDownload = async (url: string, name: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    saveAs(blob, `${name}_${new Date().toLocaleDateString()}.pdf`);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};

const InfoSideBar = () => {
  const dispatch: Function = useDispatch();
  const monthlyChronicleUrl = useSelector(
    (state: any) => state.profile.monthlyChronicleUrl
  );

  useEffect(() => {
    dispatch(getMonthlyChronicleUrl());
  }, []);

  const videoContainer = useMemo(
    () => (
      <div className="video-container">
        <ReactPlayer
          url={environment.macroPointVideoUrl}
          width={"100%"}
          height={"10.5rem"}
          controls
        />
      </div>
    ),
    [environment.macroPointVideoUrl]
  );

  const currentMarket = useMemo(
    () => (
      <div
        className="download"
        onClick={() =>
          handleDownload(environment.currentMarketDocUrl, "MarchesActuels")
        }
      >
        <img src={whatToThink} className="doc-img" alt="whatToThink" />
      </div>
    ),
    [environment.currentMarketDocUrl]
  );

  const stockMarket = useMemo(
    () => (
      <div
        className="download"
        onClick={() =>
          handleDownload(environment.financialMarketDocUrl, "MarchesFinanciers")
        }
      >
        <img src={whyInvest} className="doc-img" alt="whyInvest" />
      </div>
    ),
    [environment.financialMarketDocUrl]
  );

  const monthlyChronicle = useMemo(
    () => (
      <a href={monthlyChronicleUrl} target="_blank" className="download">
        <img
          src={chroniqueMensuelle}
          className="doc-img"
          alt="chronique mensuelle"
        />
      </a>
    ),
    [monthlyChronicleUrl]
  );

  return (
    <div className="info-side-bar">
      <div className="info-side-bar__content">
        <div className="info-side-bar__content__title">En savoir plus</div>
        <MoreInfoP
          title={"Tuto Elkano Allocation"}
          extraClassName="pdf-only"
          content={videoContainer}
        />
        <MoreInfoP title={"Perspectives macro"} content={currentMarket} />
        <MoreInfoP
          title={"Pourquoi investir sur les marchés financiers ?"}
          content={stockMarket}
        />
        <MoreInfoP title={"Chronique Mensuelle"} content={monthlyChronicle} />
        <StaffContainer />
      </div>
    </div>
  );
};

export default InfoSideBar;
