import JsPDF from "jspdf";
import { useState } from "react";
import { cssSelectorToDataURL } from "utils/cssSelectorToDataURL";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export const useGeneratePdf = () => {
  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  const handleDownloadPdf = async () => {
    setIsGenerating(true);
    try {
      const pdf = new jsPDF("portrait", "cm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const page_1 = document.querySelector(".pdf__pages__one") as HTMLElement;

      if (page_1) {
        const canvas = await html2canvas(page_1, { scale: 2 });
        const imageData = canvas.toDataURL("image/png");
        pdf.addImage(imageData, "PNG", 0, 0, pdfWidth, pdfHeight);
      } else {
        console.error("Element not found");
      }
      const page_2 = document.querySelector(".pdf__pages__two") as HTMLElement;

      if (page_2) {
        pdf.addPage();
        const canvas = await html2canvas(page_2, { scale: 2 });
        const imageData = canvas.toDataURL("image/png");
        pdf.addImage(imageData, "PNG", 0, 0, pdfWidth, pdfHeight);
      } else {
        console.error("Element not found");
      }

      const page_3 = document.querySelector(
        ".pdf__pages__three"
      ) as HTMLElement;

      if (page_3) {
        pdf.addPage();
        const canvas = await html2canvas(page_3, { scale: 2 });
        const imageData = canvas.toDataURL("image/png");
        pdf.addImage(imageData, "PNG", 0, 0, pdfWidth, pdfHeight);
      } else {
        console.error("Element not found");
      }
      const page_4 = document.querySelector(".pdf__pages__four") as HTMLElement;

      if (page_4) {
        pdf.addPage();
        const canvas = await html2canvas(page_4, { scale: 2 });
        const imageData = canvas.toDataURL("image/png");
        pdf.addImage(imageData, "PNG", 0, 0, pdfWidth, pdfHeight);
      } else {
        console.error("Element not found");
      }

      const page_5 = document.querySelector(".pdf__pages__five") as HTMLElement;

      if (page_5) {
        pdf.addPage();
        const canvas = await html2canvas(page_5, { scale: 2 });
        const imageData = canvas.toDataURL("image/png");
        pdf.addImage(imageData, "PNG", 0, 0, pdfWidth, pdfHeight);
      } else {
        console.error("Element not found");
      }
      const dateTime = new Date().toLocaleDateString().replace(/\//g, "-");
      pdf.save(`ElkanoReport_${dateTime}.pdf`);
    } catch (error) {
      console.error(error);
    }
    setIsGenerating(false);
  };
  return { handleDownloadPdf, isGenerating };
};
