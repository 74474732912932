import { Link, TextField } from "@mui/material";
import "./forgot-password-form.scss";
import TinyLogo from "assets/authentication/tinyLogo.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormState } from "../login/login";
import ColorButton from "../color-button/color-button";
import { StringStore } from "StringStore";
import { resetPassword } from "../../authSlices";
import { useDispatch, useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Required"),
  emailAgain: Yup.string().required("Required"),
});

interface ForgotPasswordFormProps {
  email: string;
  emailAgain: string;
}

const ForgotPasswordForm = ({ setFormState }) => {
  const dispatch: Function = useDispatch();
  const isEmailSent = useSelector((state: any) => state.auth.isEmailSent);
  const formik = useFormik<ForgotPasswordFormProps>({
    initialValues: {
      email: "",
      emailAgain: "",
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: ({ email }) => {
      dispatch(resetPassword(email));
    },
  });

  const isSameValidEmails = () => {
    const isSameEmails = formik.values.email === formik.values.emailAgain;
    const isEmailValid = formik.values.email.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    return isSameEmails && isEmailValid;
  };
  return (
    <div className="forgot-password-form">
      <div className="forgot-password-form__header">
        <img
          src={TinyLogo}
          alt="Tiny Logo"
          className="forgot-password-form__logo"
        />
        <div className="forgot-password-form__title">
          {StringStore.authentication.forgotPassword.title}
        </div>
      </div>
      <div className="forgot-password-form__inputs">
        <TextField
          id="standard-basic"
          name={"email"}
          label={StringStore.authentication.forgotPassword.email}
          variant="standard"
          onChange={formik.handleChange}
          className="forgot-password-form__inputs__one "
        />
        <TextField
          id="standard-basic"
          name={"emailAgain"}
          label={StringStore.authentication.forgotPassword.emailAgain}
          variant="standard"
          onChange={formik.handleChange}
          className="forgot-password-form__inputs__one "
        />
        <Link
          component={"button"}
          onClick={() => setFormState(FormState.Login)}
          color={"#D0D0D0"}
          underline="none"
        >
          {StringStore.authentication.forgotPassword.navigationLabel}
        </Link>
      </div>
      {isEmailSent && (
        <div className="forgot-password-form__inputs__success">
          {StringStore.authentication.forgotPassword.success}
        </div>
      )}
      <ColorButton
        variant="contained"
        disabled={!formik.isValid || !isSameValidEmails()}
        onClick={() => formik.handleSubmit()}
      >
        {StringStore.authentication.forgotPassword.buttonLabel}
      </ColorButton>
    </div>
  );
};

export default ForgotPasswordForm;
