// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signature {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0.5cm;
}
.signature__date {
  display: flex;
  flex-direction: row;
  gap: 0.3cm;
  font-size: 0.5cm;
  margin-top: 1cm;
  padding-left: 1cm;
}
.signature__sides {
  margin-top: 1.5cm;
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 1cm;
}
.signature__sides__name-field {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5cm;
  font-size: 0.5cm;
}
.signature__sides__name-field__title {
  font-size: 0.5cm;
  width: -moz-fit-content;
  width: fit-content;
}
.signature__sides__name-field__value {
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.5cm;
  color: #4BABB9;
}
.signature__sides__name-field__signature {
  width: -moz-fit-content;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/features/pdf/components/signature/signature.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,WAAA;EACA,aAAA;EACA,2BAAA;EACA,sBAAA;EACA,cAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,UAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AACR;AACI;EACI,iBAAA;EACA,WAAA;EACA,WAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,iBAAA;AACR;AAAQ;EACI,UAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,UAAA;EACA,gBAAA;AAEZ;AAAY;EACI,gBAAA;EACA,uBAAA;EAAA,kBAAA;AAEhB;AAAY;EACI,uBAAA;EAAA,kBAAA;EACA,gBAAA;EACR,cAAA;AAER;AACY;EACI,uBAAA;EAAA,kBAAA;AAChB","sourcesContent":[".signature{\n    width: 100%;\n    height: 40%;\n    display: flex;\n    justify-content: flex-start;\n    flex-direction: column;\n    padding: 0.5cm;\n\n    &__date{\n        display: flex;\n        flex-direction: row;\n        gap:0.3cm;\n        font-size: 0.5cm;\n        margin-top: 1cm;\n        padding-left: 1cm;\n    }\n    &__sides{\n        margin-top: 1.5cm;\n        width: 100%;\n        height: 20%;\n        display: flex;\n        justify-content: flex-start;\n        flex-direction: row;\n        padding-left: 1cm;\n        &__name-field{\n            width: 50%;\n            display: flex;\n            flex-direction: column;\n            justify-content: flex-start;\n            gap:0.5cm;\n            font-size: 0.5cm;\n            \n            &__title{\n                font-size: 0.5cm;\n                width: fit-content;\n            }\n            &__value{\n                width: fit-content;\n                font-size: 0.5cm;\n        color: #4BABB9;\n\n            }\n            &__signature{\n                width: fit-content;\n            \n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
