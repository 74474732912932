// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4BABB9;
  color: white;
  width: 16rem;
  height: 2rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.pdf__pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 1.2rem;
  margin-top: 1rem;
}
.pdf__pages__one, .pdf__pages__two, .pdf__pages__three, .pdf__pages__four, .pdf__pages__five {
  width: 21cm;
  height: 29.7cm;
  border: 1px solid #000;
  position: relative;
}
.pdf__pages__one__row, .pdf__pages__two__row, .pdf__pages__three__row, .pdf__pages__four__row, .pdf__pages__five__row {
  display: flex;
  flex-direction: row;
}
.pdf__pages__one__title {
  margin-top: 0.5cm;
  font-size: 0.5cm;
  font-weight: 800;
}
.pdf__pages__five__disclaimer {
  position: absolute;
  bottom: 0;
  font-size: 0.25cm;
  font-weight: 400;
  text-align: left;
  padding: 0.5cm;
}
.pdf .aggregated-performance-pdf {
  margin-bottom: 1cm;
}`, "",{"version":3,"sources":["webpack://./src/features/pdf/components/profile/elkano-pdf.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,qBAAA;EACA,eAAA;AAAR;AAEI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;AAAR;AACQ;EAKI,WAAA;EACA,cAAA;EACA,sBAAA;EACA,kBAAA;AAHZ;AAKY;EACI,aAAA;EACA,mBAAA;AAHhB;AAQY;EAEI,iBAAA;EACA,gBAAA;EACA,gBAAA;AAPhB;AAYY;EACI,kBAAA;EACA,SAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;AAVhB;AAeI;EACI,kBAAA;AAbR","sourcesContent":[".pdf{\n    &__button{\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        background-color: #4BABB9;\n        color: white;\n        width: 16rem;\n        height: 2rem;\n        border-radius: 0.8rem;\n        cursor: pointer;\n    }\n    &__pages{\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        width: 100%;\n        height: 100%;\n        gap: 1.2rem;\n        margin-top: 1rem;\n        &__one,\n        &__two,\n        &__three,\n        &__four,\n        &__five{\n            width: 21cm;\n            height: 29.7cm;\n            border: 1px solid #000;\n            position: relative;\n\n            &__row{\n                display: flex;\n                flex-direction: row;\n            }\n        }\n\n        &__one{\n            &__title{\n\n                margin-top: 0.5cm;\n                font-size: 0.5cm;\n                font-weight: 800;\n            }\n        }\n        &__five{\n\n            &__disclaimer{\n                position: absolute;\n                bottom: 0;\n                font-size: 0.25cm;\n                font-weight: 400;\n                text-align: left;\n                padding: 0.5cm;\n            }\n        }\n\n    }\n    .aggregated-performance-pdf{\n        margin-bottom: 1cm;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
