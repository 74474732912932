import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import {checkAutoLogin} from 'features/authentication/authSlices'

export const AuthContext = React.createContext(null)

const AuthProvider = ({ children }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const isLoading = useSelector(state => state.auth.isLoading)
  const isLoadingAutoLogin = useSelector(state => state.auth.isLoadingAutoLogin)

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    checkAutoLogin(dispatch, navigate, location)
  }, [])

  const value = {
    isAuthenticated,
    isLoading,
    isLoadingAutoLogin,
    // onLogin: handleLogin,
    // onLogout: handleLogout,
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider