// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commentary {
  width: 50%;
  height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
}
.commentary__title {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 1rem;
}
.commentary__text {
  font-size: 1rem;
  font-weight: 400;
  text-align: start;
  margin-bottom: 3rem;
}
.commentary__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EF936C;
  color: white;
  width: 16rem;
  height: 1.5rem;
  border-radius: 0.5rem;
  align-self: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/commentary-container/commentary-container.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,aAAA;AACJ;AAAI;EACI,kBAAA;EACA,gBAAA;EACA,mBAAA;AAER;AAAI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAER;AAAI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,cAAA;EACA,qBAAA;EACA,kBAAA;EACA,eAAA;AAER","sourcesContent":[".commentary{\n    width: 50%;\n    height: 12rem;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 1rem;\n    &__title{\n        font-size: 1.25rem;\n        font-weight: 800;\n        margin-bottom: 1rem;\n    }\n    &__text{\n        font-size: 1rem;\n        font-weight: 400;\n        text-align: start;\n        margin-bottom: 3rem;\n    }\n    &__button{\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        background-color: #EF936C;\n        color: white;\n        width: 16rem;\n        height: 1.5rem;\n        border-radius: 0.5rem;\n        align-self: center;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
