import "./login.scss";
import FullLogo from "assets/authentication/fullLogo.svg";
import BoatLogo from "assets/authentication/boatSvg.svg";
import LoginForm from "../login-form/login-form";
import { useEffect, useState } from "react";
import ForgotPasswordForm from "../forgot-password-form/forgot-password-form";
import FirstLoginForm from "../first-login-form/first-login-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppRoutes } from "../../types/app-routes";
import { getProfiles } from "features/profile/profileSlices";

export enum FormState {
  Login = "Login",
  FirstLogin = "FirstLogin",
  ForgotPassword = "ForgotPassword",
}

const Login = () => {
  const navigate = useNavigate();
  const dispatch: Function = useDispatch();
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const isFirstLogin = useSelector((state: any) => state.auth.user?.firstLogin);
  const [formState, setFormState] = useState<FormState>(FormState.Login);
  const formDict = {
    [FormState.Login]: <LoginForm setFormState={setFormState} />,
    [FormState.FirstLogin]: <FirstLoginForm />,
    [FormState.ForgotPassword]: (
      <ForgotPasswordForm setFormState={setFormState} />
    ),
  };

  useEffect(() => {
    if (isAuthenticated) dispatch(getProfiles());
    if (isAuthenticated && !isFirstLogin) navigate(AppRoutes.HOME);
  }, [isAuthenticated]);

  return (
    <div className="login">
      <div className="login__company-container">
        <img src={FullLogo} alt="Full Logo" />
        <img src={BoatLogo} alt="Boat Logo" />
      </div>
      <div className="login__form-container">{formDict[formState]}</div>
    </div>
  );
};

export default Login;
