import { StringStore } from "StringStore";
import "./description-container-pdf.scss";
import EquilibreRatioImg from "assets/profile/equilibreRatioImg.jpg";
import ModereRatioImg from "assets/profile/modereRatioImg.jpg";
import DynamicRatioImg from "assets/profile/dynamicRatioImg.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getDescripton } from "features/profile/profileSlices";
import { useEffect, useMemo } from "react";
import { textFormatter } from "utils/fileFormatter";

const DescriptionContainerPdf = () => {
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const description = useSelector((state: any) => state.profile.description);
  const formattedDescription = textFormatter(description);
  const imageByProfileType = useMemo(() => {
    switch (currentProfile.type) {
      case "Dynamique":
        return DynamicRatioImg;
      case "Equilibré":
        return EquilibreRatioImg;
      case "Modéré":
        return ModereRatioImg;
      default:
        return "";
    }
  }, [currentProfile.type]);
  useEffect(() => {
    dispatch(getDescripton(currentProfile.code));
  }, [currentProfile.code]);

  return (
    <div className="desc-container-pdf">
      <div className="desc-container-pdf__explanation">
        <div className="desc-container-pdf__explanation__title">
          {StringStore.profile.explanationTitle}
        </div>
        <p
          className="desc-container-pdf__explanation__text"
          dangerouslySetInnerHTML={{ __html: formattedDescription }}
        ></p>
      </div>
      <div className="desc-container-pdf__ratio">
        <div className="desc-container-pdf__ratio__title">
          {StringStore.profile.ratioTitle}
        </div>
        <img
          src={imageByProfileType}
          alt="Logo"
          className="desc-container-pdf__ratio__logo"
        />
      </div>
    </div>
  );
};

export default DescriptionContainerPdf;
