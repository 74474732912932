import TopBar from "features/profile/components/top-bar/top-bar";
import "./admin.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ClientDBPage from "../client-db-page/client-db-page";
import { getAllUsers } from "features/authentication/userSlices";
import DashboardAnalytics from "../dashboard-analytics/dashboard-analytics";
import ListIcon from "assets/admin/icon-list.svg";
import HamburgerIcon from "assets/admin/icon-hamburger.svg";
import SignalIncon from "assets/admin/icon-signal.svg";
import ClientAnalytics from "../client-analytics/client-analytics";
export enum SortOptions {
  ASC = "ASC",
  DESC = "DESC",
}
enum NavOptions {
  DATABASE_CLIENT = "Database Client",
  DASHBOARD_ANALYTICS = "Dashboard Analytics",
  CLIENT_ANALYTICS = "Clients Analytics"
}
const Admin = () => {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState(NavOptions.DATABASE_CLIENT);
  const users = useSelector((state: any) => state.user.users) || [];
  const fetchingUsers = useSelector((state: any) => state.user.fetchingUsers);

  useEffect(() => {
    dispatch(getAllUsers());
    }, [fetchingUsers]);
    
  const getIconPerOption = (option: NavOptions) => {
    switch(option) {
      case NavOptions.DATABASE_CLIENT:
        return ListIcon;
      case NavOptions.DASHBOARD_ANALYTICS:
        return SignalIncon;
      case NavOptions.CLIENT_ANALYTICS:
        return HamburgerIcon;
      default:
        return ListIcon;
    }
  }
  const getSelectedPage = () => {
    switch(selectedPage) {
      case NavOptions.DATABASE_CLIENT:
        return <ClientDBPage users={users} />
      case NavOptions.DASHBOARD_ANALYTICS:
        return <DashboardAnalytics />
      case NavOptions.CLIENT_ANALYTICS:
        return <ClientAnalytics users={users} />
      default:
        return <ClientDBPage users={users} />
    }
  }
  return (
    <div className="admin">
      <TopBar adminAccess={true} adminView={true} />
      <div className="admin__container">
        <div className="admin__sidebar">
          {
            Object.keys(NavOptions).map((option, index) => {
              const isSelected = selectedPage === NavOptions[option];
              return (
                <div key={index} onClick={()=>setSelectedPage(NavOptions[option])} className={`admin__sidebar__option ${isSelected?'selected':''}`}>
                  <img src={getIconPerOption(NavOptions[option])} alt="icon" />
                  {NavOptions[option]}
                </div>
              )
            })
          }
          </div>
          {
            getSelectedPage()
          }
    </div>
    </div>
  );
};

export default Admin;
