import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children, condition, fallBack }) => {
  const location = useLocation();

  if (!condition) {
    return <Navigate to={fallBack} state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
