import './eula.scss';
import FullLogo from 'assets/eula/fullLogo.svg'
import BoatLogo from 'assets/eula/boat.svg'
import { useDispatch } from 'react-redux';
import { FC } from 'react';
import { acceptEula } from 'features/authentication/authSlices';

const eulaText = `<article>
<p>Avant d’entrer sur le site d’Elkano Asset Management nous vous invitons à sélectionner votre profil d’investisseur, à lire les conditions suivantes et à les accepter.</p>
<p>Les informations disponibles sur le site ne constituent pas une sollicitation, un conseil, une offre de souscription ou une recommandation personnalisée. La responsabilité de Elkano Asset Management ne pourra être engagée à la suite de la consultation de ces informations.</p>
<p>Les opinions et estimations données dans ce document constituent notre jugement et sont susceptibles de changer sans préavis, de même que les éventuelles assertions quant aux tendances des marchés financiers, qui sont fondées sur les conditions actuelles de ces marchés. Votre attention est attirée sur le fait que toute prévision a ses propres limites et que par conséquent aucun engagement n’est pris par Elkano Asset Management quant à la réalisation de ces prévisions.</p>
<p>Les OPC et services qui sont présentés sur ce site sont autorisés à la commercialisation en France et accessibles aux résidents français. Les personnes soumises à des restrictions interdisant l’accès aux produits et services présentés, tels que par exemple les ressortissants des Etats-Unis ne sont pas autorisés à y accéder. Elkano Asset Management attire l’attention des investisseurs sur le fait que tout investissement comporte des risques et que la variation des marchés financiers peut entraîner des baisses des valeurs liquidatives des OPC et portefeuilles gérés. Préalablement à tout investissement, il est nécessaire de se rapprocher de son conseil pour évaluer et s’assurer de l’adéquation du produit ou du service en fonction de sa situation, de son profil et de ses objectifs.</p>
<p>Les caractéristiques financières présentées dans les pages suivantes du fait de leur simplification sont partielles et ne remplacent pas celles figurant dans les prospectus et les documents contractuels des mandats que la société vous invite à consulter avant tout investissement.</p>
<p>Elkano Asset Management vous rappelle que les performances passées ne constituent pas un indicateur fiable des performances futures. L’information fournie sur le site est jugée fiable par Elkano Asset Management, mais elle ne doit pas être considérée comme exhaustive. Les données, graphiques ou extraits ont été calculés ou effectués sur la base d’informations publiques qui n’ont pas fait l’objet d’une vérification indépendante de notre part.</p>
</article>`
interface EulaProps {
    setEulaAccepted: Function
}
const Eula :FC<EulaProps>= ({setEulaAccepted}) => {
    const dispatch: Function = useDispatch()
    const handleAcceptEula = () => {
        dispatch(acceptEula())
        setEulaAccepted(true)
    }
    return (
        <div className="eula">
            <div className="eula__container">
                <div className="eula__container__image">
                <img src={FullLogo} alt="Full Logo" />
                <img src={BoatLogo} alt="Boat Logo" />
                </div>
                <div className="eula__container__agreement">
                <div className="eula__container__agreement__title">
                Avertissement
                </div>
                <div className="eula__container__agreement__text" dangerouslySetInnerHTML={{__html:eulaText}}>
                </div>
                <div className="eula__container__agreement__button-container">
                    <div className="eula__container__agreement__button-container__button" onClick={()=>handleAcceptEula()}>Accepter</div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Eula;
                    