import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText('#000000'),
    backgroundColor:'#000000',
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: '#000000',
    },
  }));

export default ColorButton;