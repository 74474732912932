import { addEvent } from "features/authentication/userSlices";
import { useDispatch, useSelector } from "react-redux";

enum EventTypes {
    PROFILE_VIEW = "Changement de profil",
    LOGIN = "login",
    GENERATE_PDF = "Générer un portefeuille conseillé"
}
export const useElkanoAnalytics = () => {
    const dispatch:Function = useDispatch();
    const user = useSelector((state:any) => state.auth.user);

    const addProfileViewEvent = async (profile: string) => {
        await addAnalyticsEvent(EventTypes.PROFILE_VIEW, profile);
    };

    const addGeneratePdfEvent = async (profile: string) => {
        await addAnalyticsEvent(EventTypes.GENERATE_PDF, profile);
    }
    const addAnalyticsEvent = async (eventType: EventTypes, profile: string) => {
        const event = {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            company: user.company,
            type: eventType,
            profile: profile,
            date: new Date().toISOString()
        
        }
        dispatch(addEvent(event))
    }
    return { addProfileViewEvent, addGeneratePdfEvent};
};
