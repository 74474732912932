import {
  Box,
  Chip,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import "./client-form.scss";
import CloseIcon from "assets/admin/close-ic.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { StringStore } from "StringStore";
import { useDispatch, useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import { createUser, updateUser } from "features/authentication/userSlices";

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  company: Yup.string().required("Required"),
});

export interface Client {
  id?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  company?: string;
  profiles?: string[];
}
export interface ClientFormProps {
  isNew: boolean;
  isVisible: boolean;
  setVisible: Function;
  client?: Client;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ClientForm: FC<ClientFormProps> = ({
  isNew,
  isVisible,
  setVisible,
  client,
}) => {
  const dispatch: Function = useDispatch();
  const theme = useTheme();
  const [profiles, setProfiles] = useState<string[]>([]);
  const profileToCode =
    useSelector((state: any) => state.profile.profiles) || {};
  const formik = useFormik<Client>({
    initialValues: {
      firstname: client?.firstname ?? "",
      lastname: client?.lastname ?? "",
      email: client?.email ?? "",
      company: client?.company ?? "",
      profiles: client?.profiles ?? [],
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: ({ firstname, lastname, email, company }) => {
      if (isNew) {
        dispatch(createUser(firstname, lastname, email, company, profiles));
      } else {
        dispatch(
          updateUser(client.id, firstname, lastname, email, company, profiles)
        );
      }
      formik.resetForm();
    },
  });

  const handleActionButton = () => {
    formik.handleSubmit();
    setVisible(false);
  };

  useEffect(() => {
    if (client) {
      formik.setValues(client);
      setProfiles(client.profiles ?? []);
    }
  }, [client]);

  const handleChange = (event: SelectChangeEvent<typeof profiles>) => {
    const {
      target: { value },
    } = event;
    setProfiles(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  function getStyles(
    name: string,
    personName: readonly string[],
    theme: Theme
  ) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  return (
    <div className={`client-form ${isVisible ? "visible" : ""}`}>
      <div className="client-form__header">
        <div className="client-form__header__title">
          {isNew
            ? StringStore.admin.clientForm.addTitle
            : StringStore.admin.clientForm.modifyTitle}
        </div>
        <img
          src={CloseIcon}
          alt="Close"
          className="client-form__header__close"
          onClick={() => setVisible(false)}
        />
      </div>
      <div className="client-form__inputs">
        <TextField
          id="standard-basic"
          value={formik.values.firstname}
          name={"firstname"}
          label={StringStore.admin.clientForm.firstName}
          variant="standard"
          onChange={formik.handleChange}
          className="client-form__inputs__one"
        />
        <TextField
          id="standard-basic"
          value={formik.values.lastname}
          name={"lastname"}
          label={StringStore.admin.clientForm.name}
          variant="standard"
          onChange={formik.handleChange}
          className="client-form__inputs__one"
        />
        <TextField
          id="standard-basic"
          value={formik.values.email}
          name={"email"}
          label={StringStore.admin.clientForm.email}
          variant="standard"
          onChange={formik.handleChange}
          className="client-form__inputs__one"
        />
        <TextField
          id="standard-basic"
          value={formik.values.company}
          name={"company"}
          label={StringStore.admin.clientForm.company}
          variant="standard"
          onChange={formik.handleChange}
          className="client-form__inputs__one"
        />
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={profiles}
          placeholder="Profil(s)"
          onChange={handleChange}
          input={<Input id="select-multiple-chip" placeholder="Profil(s)" />}
          renderValue={(selected) => {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={profileToCode[value]?.name}
                    className="chip"
                  />
                ))}
              </Box>
            );
          }}
          MenuProps={MenuProps}
        >
          {Object.values(profileToCode).map((profile: any) => (
            <MenuItem
              key={profile?.name}
              value={profile?.code}
              style={getStyles(profile.code, profiles, theme)}
            >
              {profile?.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="client-form__footer">
        <div
          className="client-form__footer__button"
          onClick={() => handleActionButton()}
        >
          {isNew
            ? StringStore.admin.clientForm.addButtonLabel
            : StringStore.admin.clientForm.modifyButtonLabel}
        </div>
      </div>
    </div>
  );
};

export default ClientForm;
