import { useState } from 'react';
import './file-upload-section.scss';
import UploadForm from '../upload-form/upload-form';

const FileUploadSection = () => {
    const [isUploadFormVisible, setIsUploadFormVisible] = useState(false)
    const [uploadFormData, setUploadFormData] = useState(null)

    const handleClick = (buttonData) => {
        setUploadFormData(buttonData)
        setIsUploadFormVisible(true)
    }

    const buttons = [
        {
            title: 'PDF Marchés Actuels',
            fileName: 'doc1.pdf',
            type: 'pdf'
        },
        {
            title: 'PDF Naviguer sur les...',
            fileName: 'doc2.pdf',
            type: 'pdf'
        },
        {
            title: 'Vidéo Point Macro',
            fileName: 'video.mp4',
            type: 'mp4'
        }
    ]
    return (<div className="file-upload-section">
            <div className="file-upload-section__title">Fichiers section “En savoir plus”:</div>
            <div className="file-upload-section__button-container">
            {
                buttons.map((button, index) => {
                    return (
                        <div className="file-upload-section__button-container__button" onClick={()=>handleClick(button)} key={index}>{button.title}</div>
                    )
                })
            }
            </div>
            <UploadForm  {...uploadFormData} isVisible={isUploadFormVisible} setVisible={setIsUploadFormVisible} />
            </div>
        )
}

export default FileUploadSection;