import { useEffect, useMemo, useState, FC } from "react";
import "./top-bar.scss";
import Logo from "assets/authentication/topBarLogo.svg";
import { setCurrentProfile } from "features/profile/profileSlices";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "features/authentication/authSlices";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "features/authentication/types/app-routes";

interface TopBarProps {
  adminAccess: boolean;
  adminView: boolean;
  profileAccess?: boolean;
}

const TopBar: FC<TopBarProps> = ({
  adminAccess,
  adminView,
  profileAccess = false,
}) => {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);
  const profileToCode = useSelector((state: any) => state.profile.profiles);
  const basedProfile = user?.profiles?.[0] || "";
  const [selectedProfile, setSelectedProfile] = useState(basedProfile);

  const handleSelectedProfile = (profileType: string) => {
    dispatch(setCurrentProfile(profileToCode[profileType]));
    setSelectedProfile(profileType);
  };

  const handleLogout = () => {
    dispatch(logoutUser(navigate));
  };

  const handleAdminPage = () => {
    navigate(AppRoutes.ADMIN);
  };

  const handleProfilePage = () => {
    navigate(AppRoutes.HOME);
  };
  const profileButtons = useMemo(() => {
    if ((adminAccess && adminView) || profileAccess)
      return (
        <div
          className={`top-bar__profile-container__admin-button selected`}
          onClick={() => handleProfilePage()}
        >
          {"View Client Side"}
        </div>
      );
    const userProfiles = user?.profiles || [];
    if (userProfiles.length <= 4) {
      return userProfiles.map((profileType) => {
        const isSelected = profileType === selectedProfile;
        return (
          <div
            key={profileType}
            className={`top-bar__profile-container__button ${
              isSelected ? "selected" : ""
            }`}
            onClick={() => handleSelectedProfile(profileType)}
          >
            {profileType}
          </div>
        );
      });
    } else {
      return (
        <div className="top-bar__profile-container__button-container">
          <select
            className="top-bar__profile-container__button-container__select"
            value={selectedProfile}
            onChange={(e) => handleSelectedProfile(e.target.value)}
          >
            {userProfiles.map((profileType) => {
              return (
                <option
                  className="top-bar__profile-container__button-container__select__option"
                  key={profileType}
                  value={profileType}
                >
                  {profileType}
                </option>
              );
            })}
          </select>
        </div>
      );
    }
  }, [selectedProfile, user?.profiles, profileToCode]);

  useEffect(() => {
    setSelectedProfile(basedProfile);
  }, [user?.profiles]);

  return (
    <div className="top-bar">
      <div className="top-bar__logo-container">
        <img src={Logo} alt="Logo" />
        <div
          className="top-bar__logo-container__button"
          onClick={() => handleLogout()}
        >
          Log out
        </div>
      </div>
      <div className="top-bar__profile-container">
        {adminAccess && !adminView && (
          <div
            className={`top-bar__profile-container__admin-button`}
            onClick={() => handleAdminPage()}
          >
            {"Admin"}
          </div>
        )}
        {profileButtons}
      </div>
    </div>
  );
};

export default TopBar;
