import Papa from 'papaparse';
import { useDispatch } from 'react-redux';
import { createUser } from '../userSlices';

const useUsersCsv = () => {
  const dispatch: Function = useDispatch();
      const headerMap = {
        firstname: "Prénom",
        lastname: "Nom",
        email: "Email",
        company: "Société",
        profiles: "Profiles"
      };
    
      const transformData = (data, map) => {
        return data.map(item => {
          const newItem = {};
          Object.keys(map).forEach(key => {
            if (item[key] !== undefined) {
              newItem[map[key]] = item[key];
            }
          });
          return newItem;
        });
      };
    
      const exportUsersTableToCsv = (jsonData, headers) => {
        const transformedData = transformData(jsonData, headers);
        const csv = Papa.unparse(transformedData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'users.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    
      const importUsersTableFromCsv = (file) => {
        try{
          if(file.type !== 'text/csv') {
            throw new Error('Invalid file type');
          }

          const invertedHeaderMap = Object.entries(headerMap).reduce((acc, [key, value]) => {
            acc[value] = key;
            return acc;
          }, {});
      
        Papa.parse(file, {
          complete: function(results) {
            const transformedData = results.data.map(row => {
              const transformedRow = {};
              Object.keys(row).forEach(header => {
                const jsonKey = invertedHeaderMap[header];
                if (jsonKey) {
                  transformedRow[jsonKey] = row[header];
                }
              });
              return transformedRow;
            });
      
            
            transformedData.filter(data => data.firstname !==  '').forEach(({ firstname, lastname, email, company, profiles }) => {
              dispatch(createUser(firstname, lastname, email, company, profiles?.split(',')));
            });
          },
          header: true
        });
      } catch(e){
        console.error(e);
        alert("Erreur lors de l'importation du fichier, veuillez utiliser un fichier CSV valide")
      }
      };

    return { exportUsersTableToCsv, importUsersTableFromCsv };
}
export default useUsersCsv;
