import axios from 'axios'


const instance = axios.create({
  baseURL: 'https://www.avodai.com'
})

export const ENDPOINTS = {
  auth: "/auth",
  product: "/product",
}

export default instance
