import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useUsersCsv from "features/authentication/hooks/useUsersCsv";
import { useDispatch, useSelector } from "react-redux";
import { getEventsSortedByDate } from "features/authentication/userSlices";
import { SortOptions } from "../admin/admin";
import ClientForm from "../client-form/client-form";
import "./dashboard-analytics.scss";
import ElkSelect from "../elk-select/elk-select";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { getIconBySortOption } from "../client-db-page/client-db-page";
import { isDateBetween } from "../client-analytics/client-analytics";

const DashboardAnalytics = () => {
  const dispatch: Function = useDispatch();
  const { exportUsersTableToCsv, importUsersTableFromCsv } = useUsersCsv();
  const [sorted, setSorted] = useState(null);
  const [client, setClient] = useState(null);
  const [clientPopupOpen, setClientPopupOpen] = useState(false);
  const profileToCode = useSelector((state: any) => state.profile.profiles);
  const users = useSelector((state: any) => state.user.events) || [];
  const [tableData, setTableData] = useState([]);
  const [dates, setDates] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState(null);
  const [selectedLastNames, setSelectedLastNames] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState(null);
  const [selectedProfiles, setSelectedProfiles] = useState(null);
  const [propToSort, setPropToSort] = useState("lastname");

  useEffect(() => {
    if (users.length > 0) {
      setTableData(users);
    }
  }, [users]);

  useEffect(() => {
    //filter the  users by selected filters
    let filteredUsers = users;

    if (selectedCompanies && selectedCompanies.length > 0) {
      filteredUsers = filteredUsers.filter((user) =>
        selectedCompanies.includes(user.company)
      );
    }
    if (selectedLastNames && selectedLastNames.length > 0) {
      filteredUsers = filteredUsers.filter((user) =>
        selectedLastNames.includes(user.lastname)
      );
    }
    if (selectedTypes && selectedTypes.length > 0) {
      filteredUsers = filteredUsers.filter((user) =>
        selectedTypes.includes(user.type)
      );
    }
    if (selectedProfiles && selectedProfiles.length > 0) {
      filteredUsers = filteredUsers.filter((user) =>
        selectedProfiles.includes(user.profile)
      );
    }
    if (dates && dates[0] && dates[1]) {
      filteredUsers = filteredUsers.filter((user) => {
        return isDateBetween(user.date, dates[0], dates[1]);
      });
    }
    setTableData(filteredUsers);
  }, [
    selectedCompanies,
    selectedLastNames,
    selectedTypes,
    selectedProfiles,
    dates,
  ]);

  useEffect(() => {
    dispatch(getEventsSortedByDate());
  }, []);

  const formatISODateTimeCustom = (isoDateStr) => {
    const date = new Date(isoDateStr);
    const formattedDate = date.toLocaleDateString("fr-FR");
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate}\n${formattedTime}`;
  };
  useEffect(() => {
    let sortedUsers = users;
    if (propToSort === null || sorted === null) {
      setTableData(sortedUsers);
      return;
    }
    if (sorted === SortOptions.ASC) {
      sortedUsers = [...users].sort((a, b) => {
        if (a[propToSort] < b[propToSort]) {
          return -1;
        }
        if (a[propToSort] > b[propToSort]) {
          return 1;
        }
        return 0;
      });
    }
    if (sorted === SortOptions.DESC) {
      sortedUsers = [...users].sort((a, b) => {
        if (a[propToSort] > b[propToSort]) {
          return -1;
        }
        if (a[propToSort] < b[propToSort]) {
          return 1;
        }
        return 0;
      });
    }
    setTableData(sortedUsers);
  }, [users, sorted, propToSort]);

  const handleSort = (prop) => {
    setSorted((prev) => {
      if (prev === SortOptions.ASC) return SortOptions.DESC;
      if (prev === SortOptions.DESC) return null;
      return SortOptions.ASC;
    });
    setPropToSort(prop);
  };

  const getOptionsByProps = (prop, base = false) => {
    const data = base ? users : tableData;
    return data.reduce((acc, user) => {
      const exists = acc.some((option) => option.value === user[prop]);

      if (!exists) {
        acc.push({
          value: user[prop],
          label: user[prop],
        });
      }
      return acc;
    }, []);
  };
  const headerMap = {
    firstname: "Prénom",
    lastname: "Nom",
    email: "Email",
    company: "Société",
    type: "Évenement",
    profile: "Profile",
    date: "Date",
  };
  return (
    <div className="dashboard-analytics-page">
      <div className="dashboard-analytics-page__title">Dashboard Analytics</div>
      <div className="dashboard-analytics-page__button-container">
        <MultiSelect
          value={selectedCompanies}
          onChange={(e) => setSelectedCompanies(e.value)}
          options={getOptionsByProps("company", true)}
          filter
          placeholder="Société"
          className="mine"
        />
        <MultiSelect
          value={selectedLastNames}
          onChange={(e) => setSelectedLastNames(e.value)}
          options={getOptionsByProps("lastname", true)}
          filter
          placeholder="Nom"
          className="mine"
        />
        <MultiSelect
          value={selectedTypes}
          onChange={(e) => setSelectedTypes(e.value)}
          options={getOptionsByProps("type", true)}
          filter
          placeholder="Évenement"
          className="mine"
        />
        <MultiSelect
          value={selectedProfiles}
          onChange={(e) => setSelectedProfiles(e.value)}
          options={getOptionsByProps("profile", true)}
          filter
          placeholder="Profile"
          className="mine"
        />
        <Calendar
          placeholder="Date"
          selectionMode="range"
          value={dates}
          onChange={(e) => setDates(e.value)}
          dateFormat="dd/mm/yy"
          showButtonBar
          readOnlyInput
        />
        <div
          className="dashboard-analytics-page__button-container__button"
          onClick={() => {
            const formatedUsers = users.map((user) => ({
              ...user,
              profile: profileToCode[user.profile].name,
            }));
            exportUsersTableToCsv(formatedUsers, headerMap);
          }}
        >
          Export CSV
        </div>
      </div>
      <table>
        <thead>
          <tr>
            {Object.keys(headerMap).map((key, index) => {
              return (
                <th key={index} onClick={() => handleSort(key)}>
                  <span>{headerMap[key]}</span>{" "}
                  <img src={getIconBySortOption(sorted, key, propToSort)} />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.map((user, index) => {
            return (
              <tr key={index}>
                <td>{user.firstname}</td>
                <td>{user.lastname}</td>
                <td>{user.email}</td>
                <td>{user.company}</td>
                <td>{user.type}</td>
                <td>{profileToCode[user.profile]?.name}</td>
                <td>{formatISODateTimeCustom(user.date)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ClientForm
        isNew={client == null}
        client={client}
        isVisible={clientPopupOpen}
        setVisible={setClientPopupOpen}
      />
    </div>
  );
};

export default DashboardAnalytics;
