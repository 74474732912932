import { useEffect } from "react";
import "./distribution-chart-pdf.scss";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { getDistributionChartSeries } from "features/profile/profileSlices";
import { useSelector, useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

const labelToColor = (label: string) => {
  switch (label) {
    case "Actions":
      return "#4babb9ff";
    case "Obligations":
      return "#ef936cff";
    case "Monétaire":
      return "#031280ff";
    case "Diversifié":
      return "#7f7f7fff";
    default:
      return "";
  }
};

const getOptions = (labels: []) => {
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
      },
    },
    colors: labels.map((label) => labelToColor(label)),
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        if (val === 0) {
          return "";
        }
        return Math.round((val + Number.EPSILON) * 100) / 100 + "%";
      },
      style: {
        fontSize: "12px",
        colors: ["#FFFFFF"],
        fontWeight: 800,
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          colors: ["#000000"],
          fontSize: "12px",
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };
  return options;
};

const DistributionChartPdf = () => {
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const data = useSelector((state: any) => state.profile.distributionChartData);

  useEffect(() => {
    dispatch(getDistributionChartSeries(currentProfile.code));
  }, [currentProfile.code]);

  return (
    <div className="distribution-pdf">
      <div className="distribution-pdf__title">
        Répartition par classes d’actifs
      </div>
      {!!data.series.length ? (
        <ReactApexChart
          options={getOptions(data.labels) as ApexOptions}
          series={[{ data: data.series }]}
          type="bar"
          width={300}
          height={190}
        />
      ) : (
        <div className="distribution-pdf__content">
          <ClipLoader color={"#EF936C"} loading={true} size={100} />
        </div>
      )}
    </div>
  );
};

export default DistributionChartPdf;
