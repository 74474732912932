// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-commentary-pdf {
  display: flex;
  width: 98%;
  margin-left: 1%;
  height: auto;
  flex-direction: column;
  background-color: #F4F7F9;
  border-radius: 1cm;
}
.full-commentary-pdf__header {
  padding: 0.5cm;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.full-commentary-pdf__header__title {
  font-size: 0.5cm;
  font-family: Gotham, sans-serif;
  font-weight: 800;
  color: #000;
}
.full-commentary-pdf__header__data-date {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  font-size: 0.35cm;
  width: 4.5cm;
  height: 0.7cm;
  border-radius: 0.5cm;
}
.full-commentary-pdf__content {
  width: 90%;
  padding: 0.5cm;
  padding-top: 0;
  text-align: start;
  font-size: 0.35cm;
  font-family: Gotham, sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/features/pdf/components/full-commentary/full-commentary-pdf.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,UAAA;EACA,eAAA;EACA,YAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;AACJ;AAAI;EACI,cAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAER;AAAQ;EACI,gBAAA;EACA,+BAAA;EACA,gBAAA;EACA,WAAA;AAEZ;AAAQ;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;AAEZ;AACI;EACI,UAAA;EACA,cAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,+BAAA;AACR","sourcesContent":[".full-commentary-pdf{\n    display: flex;\n    width: 98%;\n    margin-left: 1%;\n    height: auto;\n    flex-direction: column;\n    background-color: #F4F7F9;\n    border-radius: 1cm;\n    &__header{\n        padding: 0.5cm;\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n\n        &__title{\n            font-size: 0.5cm;\n            font-family: Gotham, sans-serif;\n            font-weight: 800;\n            color: #000;\n        }\n        &__data-date{\n            display: flex;\n            justify-content: center;\n            align-items: center;\n            background-color: black;\n            color: white;\n            font-size: 0.35cm;\n            width: 4.5cm;\n            height: 0.7cm;\n            border-radius: 0.5cm;\n        }\n    }\n    &__content{\n        width: 90%;\n        padding: 0.5cm;\n        padding-top: 0;\n        text-align: start;\n        font-size: 0.35cm;\n        font-family: Gotham, sans-serif;\n       \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
