import { useEffect, useMemo, useRef, useState } from 'react';
import useUsersCsv from 'features/authentication/hooks/useUsersCsv';
import { useDispatch, useSelector } from 'react-redux';
import { getEventsSortedByDate } from 'features/authentication/userSlices';
import { SortOptions } from '../admin/admin';
import './client-analytics.scss';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { getIconBySortOption } from '../client-db-page/client-db-page';

export const isDateBetween = (targetDate, startDate, endDate) => {
    const target = new Date(targetDate);
    const start = new Date(startDate);
    const end = new Date(endDate);

    return target >= start && target <= end;
}
const ClientAnalytics = ({users}) => {
    const dispatch: Function = useDispatch();
    const { exportUsersTableToCsv } = useUsersCsv();
    const [sorted, setSorted] = useState(null);
    const [tableData, setTableData] = useState(users);
    const [dates, setDates] = useState(null);
    const [selectedCompanies, setSelectedCompanies] = useState(null);
    const [propToSort, setPropToSort] = useState('lastname');
    useEffect(() => {
        dispatch(getEventsSortedByDate());
    }, []);

    useEffect(()=>{
        let filteredUsers = users;
        if(selectedCompanies && selectedCompanies.length > 0){
            filteredUsers = filteredUsers.filter(user => selectedCompanies.includes(user.company));
        }
        if(dates && dates[0] && dates[1]){   
            const formattedUsers  = filteredUsers.map(user => {
                const loginsFiltered = user.analytics?.logins.filter(login => isDateBetween(login.date, dates[0], dates[1]));
                return ({...user, analytics: { logins: loginsFiltered, last_login:loginsFiltered[loginsFiltered.length-1]}});
            })
            filteredUsers = formattedUsers.filter(user => user.analytics?.logins.length > 0);
        }
        setTableData(filteredUsers);

    },[selectedCompanies, dates]);

    const formatISODateTimeCustom = (isoDateStr) =>{
        const date = new Date(isoDateStr);
        const formattedDate = date.toLocaleDateString('fr-FR');
        const formattedTime = date.toLocaleTimeString();
        return `${formattedDate}\n${formattedTime}`;
    }
    useEffect(() => {
        let sortedUsers = users
        if(propToSort === null || sorted === null) {
            setTableData(sortedUsers);
            return
        }
        if(sorted === SortOptions.ASC) {
            sortedUsers = [...users].sort((a, b) => {
                const userA = { ...a, logins:a.analytics?.logins, last_login:a.analytics?.last_login, total_connection_time:totalDuration(a), avg_connection_time:avgDuration(a)};
                const userB = { ...b, logins:b.analytics?.logins, last_login:b.analytics?.last_login, total_connection_time:totalDuration(b), avg_connection_time:avgDuration(b)};
            if (userA[propToSort] < userB[propToSort]) {
                return -1;
            }
            if (userA[propToSort] > userB[propToSort]) {
                return 1;
            }
            return 0;
            });
        }
        if(sorted === SortOptions.DESC) {
            sortedUsers = [...users].sort((a, b) => {
                const userA = { ...a, logins:a.analytics?.logins, last_login:a.analytics?.last_login, total_connection_time:totalDuration(a), avg_connection_time:avgDuration(a)};
                const userB = { ...b, logins:b.analytics?.logins, last_login:b.analytics?.last_login, total_connection_time:totalDuration(b), avg_connection_time:avgDuration(b)};
            if (userA[propToSort] > userB[propToSort]) {
                return -1;
            }
            if (userA[propToSort] < userB[propToSort]) {
                return 1;
            }
            return 0;
            });
        }
        setTableData(sortedUsers);

    },[users, sorted,propToSort]);

    const handleSort = (prop) => {
        setSorted(prev => {
            if(prev === SortOptions.ASC) return SortOptions.DESC;
            if(prev === SortOptions.DESC) return null;
            return SortOptions.ASC;
        })
        setPropToSort(prop);
    }
    const formatTime = (timeInMins) => { 
        const hours = Math.floor(timeInMins / 60);
        const minutes = timeInMins % 60;
        return `${hours}h ${minutes}m`;
    }
    const getOptionsByProps = (prop, base=false) => {
        const data = base ? users : tableData;
        return data.reduce((acc, user) => {
            const exists = acc.some(option => option.value === user[prop]);

            if (!exists) {
                acc.push({
                    value: user[prop],
                    label: user[prop]
                });
            }
            return acc;
        }, []);
    }
    const headerMap = {
        firstname: "Prénom",
        lastname: "Nom",
        email: "Email",
        company: "Société",
        logins: "Nombre de Connexion",
        last_login: "Dernière Connexion",
        avg_connection_time:"Durée Moyenne de Connexion",
        total_connection_time:"Durée de Connexion Totale"
      };
    const transformData = (users) => {
        return users.map(user => {
            const length = user.analytics?.logins.length;
            const avg_connection_time = avgDuration(user);
            const total_connection_time = totalDuration(user);
            const last_login = user.analytics?.logins.length > 0 ? user.analytics?.logins[user.analytics?.logins.length-1].date : '';
            return {...user, logins:length, last_login, avg_connection_time, total_connection_time}
        });
    }
    const totalDuration = (user) => {
        try{
        return user.analytics.logins?.reduce((acc, login) => {
            const duration = login.duration || 0;
            return acc + duration
        }, 0);
    } catch(e){
        return 0;
    }
    }

    const avgDuration = (user) => {
        const total = totalDuration(user);
        const length = user.analytics?.logins.length;
        return length > 0 ? Math.round(total / length) : 0;
    }

    return (
        <div className="client-analytics-page">
            <div className="client-analytics-page__title">
                Clients Analytics
            </div>
            <div className="client-analytics-page__button-container">
            <MultiSelect
                value={selectedCompanies}
                onChange={(e) => setSelectedCompanies(e.value)}
                options={getOptionsByProps('company',true)}
                filter
                placeholder="Société"
                className="mine"
            />
           <Calendar placeholder='Date' selectionMode="range" value={dates} dateFormat='dd/mm/yy' onChange={(e) => setDates(e.value)} showButtonBar readOnlyInput />

            <div
                className="client-analytics-page__button-container__button"
                onClick={() => exportUsersTableToCsv(transformData(tableData), headerMap)}
            >
                Export CSV
            </div>
           
            </div>
            <table>
            <thead>
                <tr>
                {Object.keys(headerMap).map((key, index) => {
                    return (
                        <th key={index} onClick={()=>handleSort(key)}><span>{headerMap[key]}</span> <img src={getIconBySortOption(sorted,key, propToSort)}/></th>
                    );
                }
                )}
                </tr>
            </thead>
            <tbody>
                {tableData.map((user, index) => {
                    const lastLogin = user.analytics?.logins.length > 0 ? user.analytics?.logins[user.analytics?.logins.length-1].date : '';
                    const total = totalDuration(user)
                    const avg = avgDuration(user);
                return (
                    <tr key={index}>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.email}</td>
                    <td>{user.company}</td>
                    <td>{user.analytics?.logins.length}</td>
                    <td>{user.analytics?.logins.length === 0 ? '':formatISODateTimeCustom(lastLogin)}</td>
                    <td>{formatTime(avg) || 0}</td>
                    <td>{formatTime(total) || 0}</td>
                    </tr>
                );
                })}
            </tbody>
            </table>
        </div>
        );
};

export default ClientAnalytics;