// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgot-password-form {
  display: flex;
  flex-direction: column;
}
.forgot-password-form__header {
  margin-bottom: 10%;
}
.forgot-password-form__title {
  color: #000;
  font-family: "Inter";
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.forgot-password-form__inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 5%;
  gap: 0.625rem;
}
.forgot-password-form__inputs__one {
  color: #000;
  width: 18.75rem;
  font-family: "Inter";
}
.forgot-password-form__inputs__success {
  color: #4BB534;
  font-family: "Inter";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 18.75rem;
  text-wrap: wrap;
  text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/features/authentication/components/forgot-password-form/forgot-password-form.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AACI;EACI,kBAAA;AACR;AACI;EACI,WAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACR;AACI;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,iBAAA;EACA,aAAA;AACR;AACQ;EACI,WAAA;EACA,eAAA;EACA,oBAAA;AACZ;AAEQ;EACI,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AAAZ","sourcesContent":[".forgot-password-form{\n    display: flex;\n    flex-direction: column;\n\n    &__header{\n        margin-bottom: 10%;\n    }\n    &__title{\n        color: #000;\n        font-family: 'Inter';\n        font-size: 2rem;\n        font-style: normal;\n        font-weight: 500;\n        line-height: normal;\n    }\n    &__inputs{\n        display: flex;\n        flex-direction: column;\n        align-items: flex-end;\n        margin-bottom: 5%;\n        gap: 0.625rem;\n        \n        &__one{\n            color:#000;\n            width: 18.75rem;\n            font-family: 'Inter';\n        }\n\n        &__success{\n            color: #4BB534;\n            font-family: 'Inter';\n            font-size: 1rem;\n            font-style: normal;\n            font-weight: 500;\n            line-height: normal;\n            max-width: 18.75rem;\n            text-wrap: wrap;\n            text-align: start;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
