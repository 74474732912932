import { StringStore } from "StringStore";
import "./keys-container-pdf.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getProfileKeys } from "features/profile/profileSlices";

const KeysContainerPdf = () => {
  const dispatch: Function = useDispatch();
  const currentProfile = useSelector(
    (state: any) => state.profile.currentProfile
  );
  const keysData = useSelector((state: any) => state.profile.keys);
  const risk = keysData.risk ? Number(keysData.risk).toFixed(1) : null;
  const keys = [
    {
      title: `${StringStore.profile.keys.perf} ${keysData.since}`,
      value: `${(keysData.perf * 100).toFixed(1)}%`,
    },
    {
      title: StringStore.profile.keys.risk,
      value: `${risk} sur 7`,
    },
    {
      title: `${StringStore.profile.keys.volatility} ${keysData.since}`,
      value: `${(keysData.volatility * 100).toFixed(1)}%`,
    },
    {
      title: StringStore.profile.keys.maxLoss,
      value: `${(keysData.maxLoss * 100).toFixed(1)}%`,
    },
  ];

  useEffect(() => {
    dispatch(getProfileKeys(currentProfile.code));
  }, [currentProfile.code]);

  return (
    <div className="keys-container-pdf">
      <div className="keys-container-pdf__title">
        {StringStore.profile.keys.title}
      </div>
      <div className="keys-container-pdf__data">
        <div className="keys-container-pdf__data__keys">
          {keys.map((key, index) => {
            return (
              <div
                key={`perf-indicator-${index}`}
                className="keys-container-pdf__data__keys__one__key"
              >
                <div className="keys-container-pdf__data__keys__one__title">
                  {key.title}
                </div>
                <div className="keys-container-pdf__data__keys__one__value">
                  {key.value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="keys-container-pdf__disclaimer">
        {StringStore.profile.keys.disclaimer}
      </div>
    </div>
  );
};
export default KeysContainerPdf;
