// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.performance {
  margin-top: 3rem;
  max-width: 100%;
}
.performance__title {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 1rem;
  text-align: start;
}
.performance__disclaimer {
  text-align: start;
  font-size: 0.75rem;
}
.performance__content {
  height: 16.875rem;
  width: 83.8125rem;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/performance-chart/performance-chart.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;AACJ;AAAI;EACI,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AAER;AAAI;EACI,iBAAA;EACA,kBAAA;AAER;AAAI;EACI,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAER","sourcesContent":[".performance{\n    margin-top: 3rem;\n    max-width: 100%;\n    &__title{\n        font-size: 1.25rem;\n        font-weight: 800;\n        margin-bottom: 1rem;\n        text-align: start;\n    }\n    &__disclaimer{\n        text-align: start;\n        font-size: 0.75rem;\n    }\n    &__content{\n        height: 16.875rem;\n        width: 83.8125rem;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
